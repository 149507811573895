.footer-container {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
  margin-top: 15px;
  margin-bottom: 10px;
  .powered {
    flex-basis: 250px;
    border-top: 1px solid #757575;
    text-align: center;
    span {
      color: #08b618;
    }
  }
  .version {
    text-align: center;
  }
  .term-of-use-link {
    color: #000;
  }
}
