.payroll-result-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
  }
  .title-container {
    font-size: 1.1rem;
    display: flex;
    justify-content: space-between;
    .fa-exclamation-triangle {
      color: #f44336;
      font-size: 1.5rem;
    }
    .fa-check-square {
      color: #43a047;
      font-size: 1.5rem;
    }
  }
  .result-container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    align-items: center;
    .result {
      font-size: 1.3rem;
      padding-left: 20px;
    }
    .result-error {
      color: #f44336;
      font-weight: bold;
    }
    .result-success {
      color: #43a047;
      font-weight: bold;
    }
    .error {
      font-weight: bold;
      font-size: 1.2rem;
      span.error {
        color: #f44336;
      }
      span.success {
        color: #43a047;
      }
    }
  }
  .memo-field {
    margin-top: 25px;
    textarea {
      width: 100%;
      height: 80px;
    }
  }
}

.payroll-result-dialog {
  max-width: 600px;
  left: calc((100vw - 600px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
