.close-out-container {
  width: 800px;
  margin: auto;
  border: 1px solid #5a5a5a;
  background-color: #f2f2f2;
  .title {
    background-color: #9e9e9e;
    padding: 3px 5px;
    padding-left: 15px;
    border-bottom: 1px solid #5a5a5a;
    font-weight: bold;
  }
  .top-fields {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #5a5a5a;
    padding-bottom: 8px;
    margin-bottom: 10px;
  }
  .field {
    flex-basis: 31%;
    margin-bottom: 10px;
    input[type="text"],
    input[type="number"],
    select {
      width: 100%;
      height: 25px;
    }
    input[type="checkbox"] {
      margin-top: 3px;
      position: absolute;
    }
    .project-mail-label {
      margin-left: 18px;
    }
    &.art-proofs {
      flex-basis: 18%;
      padding-left: 10px;
    }
    &.art-proof-type {
      flex-basis: 15%;
      padding-left: 10px;
      padding-right: 10px;
    }
    &.art-field {
      flex-basis: 9%;
    }
  }
  .colon-container {
    flex-basis: 10px;
    text-align: center;
    margin-top: 22px;
    font-weight: bold;
  }
  .subdepartment-container {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #5a5a5a;
    padding-top: 5px;
    padding-bottom: 8px;
    .checkbox-field {
      flex-basis: 31%;
      margin-top: 14px;
      input {
        width: 13px;
        margin-right: 5px;
      }
    }
    .field {
      margin-bottom: 0;
    }
  }
  .mailing-title {
    font-weight: bold;
    margin-top: 8px;
    font-size: 1rem;
  }
  .mailing-closeout {
    margin-top: 10px;
    border-top: 1px solid #333;
    padding-top: 8px;
    .mailing-item-title {
      font-weight: bold;
      text-align: center;
      font-size: 0.95rem;
    }
    .table-row {
      table {
        margin-bottom: 8px;
      }
      .table-title {
        font-weight: bold;
      }
      th {
        font-weight: normal;
        background-color: #e4e4e7;
      }
      th,
      td {
        border: 1px solid #9ca3af;
        padding: 1px 4px;
      }
    }
  }
  .description-field {
    border-top: 1px solid #5a5a5a;
    border-bottom: 1px solid #5a5a5a;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  .mail-closeout-data {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0;
    .mail-service-closeout,
    .inserting-closeout,
    .hands-closeout {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #5a5a5a;
      justify-content: space-between;
      padding-top: 8px;
      padding-bottom: 8px;
      .mail-closeout-title {
        font-weight: bold;
        font-size: 0.95rem;
        margin-bottom: 8px;
        flex-basis: 100%;
      }
      .data-column {
        flex-basis: 26%;
      }
      .table-column {
        flex-basis: 72%;
        .table-title {
          font-weight: bold;
        }
        th,
        td {
          border: 1px solid #5a5a5a;
          padding: 1px 4px;
        }
      }
      .notes-row {
        flex-basis: 100%;
      }
    }
  }
  .select-tasks {
    flex-basis: 100%;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 1px solid #5a5a5a;
  }
  .subdepartments-list {
    padding: 10px 15px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .fields {
    padding: 10px 15px;
    padding-bottom: 0;
    .final-quantity {
      margin-top: 15px;
    }
  }
  .textarea-field {
    flex-basis: 65.5%;
    margin-bottom: 10px;
    padding: 10px 15px;
    textarea {
      width: 100%;
    }
  }
  .update-button {
    text-align: right;
    padding: 10px 15px;
    padding-top: 0;
    button {
      width: 130px;
      height: 30px;
      border: none;
      background-color: #9e9e9e;
      color: #000;
      i {
        font-size: 1rem;
      }
    }
    &.with-subdepartments {
      margin-top: 10px;
    }
  }
  .partials-list {
    padding-left: 15px;
    padding-right: 15px;
    .partial-title {
      font-weight: bold;
      text-align: center;
    }
    th,
    td {
      border: 1px solid #666;
      padding: 1px 4px;
    }
    .total-td {
      border: none;
    }
  }
  .shippings-list {
    padding-left: 15px;
    padding-right: 15px;
    .shipping-title {
      font-weight: bold;
      text-align: center;
    }
    th,
    td {
      border: 1px solid #666;
      padding: 1px 4px;
    }
    .total-td {
      border: none;
    }
  }
  .divider {
    flex-basis: 100%;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 8px;
    border-bottom: 1px solid #333;
  }
  .partial-buttons {
    display: flex;
    .add-button,
    .remove-button {
      border: none;
      background-color: #9e9e9e;
      color: #000;
      padding: 4px 20px;
      cursor: pointer;
      font-size: 14px;
      margin-bottom: 0;
      margin-right: 15px;
      align-self: flex-start;
    }
  }
  .shipping-buttons {
    display: flex;
    .add-button,
    .remove-button {
      border: none;
      background-color: #9e9e9e;
      color: #000;
      padding: 4px 20px;
      cursor: pointer;
      font-size: 14px;
      margin-bottom: 0;
      margin-right: 15px;
    }
  }
  &.mailing-close-out-container {
    width: 1000px;
    .top-fields {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .crew-information-container {
      border-bottom: 1px solid #5a5a5a;
      padding-bottom: 8px;
      h4 {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 10px;
        button {
          width: 90px;
          height: 26px;
          border: none;
          background-color: #9e9e9e;
          color: #000;
          font-size: 0.95rem;
          i {
            font-size: 0.95rem;
          }
        }
      }
      .fields {
        padding: 0;
        .person {
          display: flex;
          justify-content: space-between;
          input[type="time"] {
            width: 100px;
            height: 25px;
          }
          .operator-field {
            flex-basis: 140px;
          }
          .roll-field {
            flex-basis: 70px;
          }
          .date-in-field {
            flex-basis: 90px;
          }
          .time-in-field {
            flex-basis: 100px;
          }
          .date-out-field {
            flex-basis: 90px;
          }
          .time-out-field {
            flex-basis: 100px;
          }
          .start-count-field {
            flex-basis: 85px;
          }
          .end-count-field {
            flex-basis: 80px;
          }
          .machine-field {
            flex-basis: 140px;
          }
          .delete-icon {
            color: #e53935;
            align-self: flex-end;
            margin-bottom: 12px;
            i {
              cursor: pointer;
              font-size: 1rem;
            }
          }
        }
      }
    }
    .details-container {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #5a5a5a;
      padding-bottom: 8px;
      margin-top: 10px;
      .job-details-container {
        flex-basis: calc(100% - 386px);
        border-right: 1px solid #5a5a5a;
        padding-right: 10px;
        h4 {
          display: flex;
          justify-content: space-between;
          font-size: 1rem;
          margin-top: 8px;
          font-weight: bold;
          button {
            width: 90px;
            height: 26px;
            border: none;
            background-color: #9e9e9e;
            color: #000;
            font-size: 0.95rem;
            i {
              font-size: 0.95rem;
            }
          }
        }
        table {
          width: 100%;
          margin-top: 8px;
          th,
          td {
            padding: 3px 2px;
          }
          input {
            width: 75px;
          }
          .actions {
            color: #e53935;
            vertical-align: middle;
            i {
              cursor: pointer;
              font-size: 1rem;
            }
          }
        }
      }
      .other-info-container {
        flex-basis: 386px;
        padding-left: 10px;
        h4 {
          display: flex;
          justify-content: space-between;
          font-size: 1rem;
          margin-top: 8px;
          font-weight: bold;
        }
        .quality-container {
          border-bottom: 1px solid #5a5a5a;
          padding-bottom: 8px;
          h4 {
            display: block;
          }
          .fields {
            padding: 0;
            margin-top: 12px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            .operators {
              .gary {
                margin-bottom: 5px;
              }
              .other {
                margin-top: 5px;
                select {
                  margin-left: 8px;
                  width: 160px;
                }
              }
            }
          }
        }
        .barcode-container {
          border-bottom: 1px solid #5a5a5a;
          padding-bottom: 8px;
          .fields {
            padding: 0;
            margin-top: 12px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            select {
              width: 160px;
            }
          }
        }
        .leftover-container {
          textarea {
            margin-top: 8px;
            width: 100%;
            height: 60px;
          }
        }
      }
    }
  }
  .browse-files-button {
    width: 150px;
    height: 28px;
    border: 1px solid #5a5a5a;
    background-color: #a3a3a3;
    color: #000;
  }
  .browse-files-button-disabled {
    width: 150px;
    height: 28px;
    border: 1px solid #a3a3a3;
  }
}

.job-closed {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 1.3rem;
  i {
    font-size: 2.8rem;
    color: #16a34a;
  }
}