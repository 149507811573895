.chat-list-container {
  border: 1px solid #009688;
  border-right: none;
  background-color: #eceff1;
  height: 80vh;
  font-size: 0.9rem;
  width: 350px;
  @media (max-width: 1200px) {
    width: 300px;
  }
  .title {
    font-size: 1.1rem;
    font-weight: bold;
    border-bottom: 1px solid #009688;
    margin-bottom: 0;
    margin-top: 8px;
    margin-left: 12px;
    margin-right: 12px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 2px;
    i {
      cursor: pointer;
      font-size: 1rem;
      margin-right: 5px;
    }
  }
  .chats {
    overflow: auto;
    height: calc(100% - 38px);
    .chat-element {
      cursor: pointer;
      padding: 2px 12px;
      border-bottom: 1px solid #c7d9d7;
      height: 48px;
      .name-container {
        display: flex;
        justify-content: space-between;
        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          flex-basis: 285px;
          @media (max-width: 1200px) {
            flex-basis: 250px;
          }
        }
        .unread-messages {
          animation-name: blink-chat-list;
          animation-duration: 1.2s;
          animation-timing-function: ease;
          animation-iteration-count: infinite;
          color: #e91e63;
          font-size: 0.95rem;
          @keyframes blink-chat-list {
            from {
              color: #f48fb1;
            }
            to {
              color: #e91e63;
            }
          }
        }
      }
      &:hover {
        background-color: #c7d9d7;
      }
      .last-message {
        display: flex;
        color: #616161;
        margin-bottom: 2px;
        font-size: 0.8rem;
        justify-content: space-between;
        .message {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 190px;
          @media (max-width: 1200px) {
            width: 160px;
          }
          &.no-messages {
            color: #bdbdbd;
          }
        }
        .time {
          font-size: 0.75rem;
          align-self: flex-end;
        }
      }
      &.active {
        background-color: #c7d9d7;
      }
    }
    .loading-data {
      margin-left: 15px;
      margin-top: 8px;
    }
    .subtitle {
      font-size: 0.85rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-left: 12px;
      margin-right: 12px;
      margin-top: 12px;
    }
  }
}
