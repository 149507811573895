.customers-container {
  padding: 10px;
  border: 1px solid #333;
  margin-top: -1px;
  margin-bottom: 15px;
  .table-container {
    margin-bottom: 0;
    table {
      margin-bottom: 0;
      th {
        background-color: #9e9e9e;
        cursor: pointer;
        &.search-th {
          padding-top: 0;
          input,
          select {
            height: 20px;
            width: 100%;
          }
        }
        &.th-id {
          width: 80px;
        }
        &.th-date {
          width: 100px;
        }
      }
      td {
        padding-top: 2px;
        padding-bottom: 2px;
        .actions {
          text-align: center;
          div {
            i {
              margin-right: 10px;
              font-size: 1rem;
              cursor: pointer;
              color: #333;
              &:hover {
                color: #2196f3;
              }
              &.fa-trash {
                &:hover {
                  color: #e53935 !important;
                }
              }
            }
            &:last-child {
              i {
                margin-right: 0;
              }
            }
          }
        }
        .combined {
          font-weight: bold;
        }
      }
    }
  }
  .actions-container {
    display: flex;
    justify-content: space-between;
    margin-top: -2px;
    .new-request-button {
      width: 130px;
      background-color: #e0e0e0;
      border: 1px solid #e0e0e0;
      margin-bottom: 8px;
    }
    .report-button {
      width: 220px;
      background-color: #e0e0e0;
      border: 1px solid #e0e0e0;
      margin-bottom: 8px;
    }
  }
}
