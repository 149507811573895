.hold-container {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  .loading-container {
    flex-basis: 100%;
    text-align: center;
  }
  .select-customer-title {
    flex-basis: 331px;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .status-title {
    flex-basis: 575px;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .customer-field {
    flex-basis: 331px;
    overflow: hidden;
    select {
      width: 305px;
      height: 22px;
    }
    .email-container {
      margin-top: 8px;
      width: 305px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .warning-field {
    flex-basis: 75px;
    margin-top: 5px;
    .label {
      font-weight: bold;
    }
    input[type="checkbox"] {
      margin-left: 20px;
    }
  }
  .hold-field {
    flex-basis: 40px;
    margin-top: 5px;
    .label {
      font-weight: bold;
    }
    input[type="checkbox"] {
      margin-left: 8px;
    }
  }
  .closed-field {
    flex-basis: 60px;
    margin-left: 15px;
    margin-top: 5px;
    .label {
      font-weight: bold;
    }
    input[type="checkbox"] {
      margin-left: 14px;
    }
  }
  .check-bounced-field {
    flex-basis: 140px;
    margin-left: 12px;
    margin-top: 5px;
    .label {
      font-weight: bold;
    }
    input[type="text"] {
      width: 110px;
      height: 22px;
      margin-left: 5px;
    }
  }
  .limit-hold-field {
    flex-basis: 145px;
    margin-left: 12px;
    margin-top: 5px;
    .label {
      font-weight: bold;
    }
    input[type="text"] {
      width: 120px;
      height: 22px;
      margin-left: 3px;
      margin-left: 5px;
    }
  }
  .other-reason-field {
    flex-basis: 260px;
    margin-left: 15px;
    margin-top: 5px;
    .label {
      font-weight: bold;
    }
    input[type="text"] {
      width: 100%;
      height: 22px;
    }
  }
  .reason-field {
    margin-top: 15px;
    flex-basis: 760px;
    textarea {
      width: 100%;
      height: 120px;
    }
  }
  .email-field {
    margin-left: 25px;
    margin-top: 35px;
    align-self: flex-start;
    input[type="checkbox"]:last-child {
      margin-top: 6px;
      margin-left: 25px;
    }
  }
}

.hold-dialog {
  max-width: 1150px;
  left: calc((100vw - 1150px) / 2);
  .modal-dialog {
    max-width: 1150px;
  }
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
