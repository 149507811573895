.pqt-pagination-container {
  display: flex;
  margin-top: 5px;
  align-items: center;
  .pagination-page,
  .pagination-prev-page,
  .pagination-next-page {
    min-width: 32px;
    padding: 5px 12px;
    background-color: #f5f5f5;
    text-align: center;
    margin-right: 5px;
    border: 1px solid #dee2e6;
    font-size: 0.8rem;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: #e0e0e0;
    }
  }
  .pagination-summary {
    margin-left: 5px;
    font-size: 0.8rem;
  }
  .pagination-dots {
    background-color: #f5f5f5;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
