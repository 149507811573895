.new-paper-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
  }
  .form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .field {
      flex-basis: 48%;
    }
    .m-price-field {
      margin-top: 5px;
    }
  }
}

.new-paper-dialog {
  max-width: 800px;
  left: calc((100vw - 800px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
