.pqn-press-form-container {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  .loading-container {
    flex-basis: 100%;
    text-align: center;
  }
  .title {
    flex-basis: 100%;
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  .fields {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .field {
      flex-basis: 48.5%;
      margin-bottom: 10px;
      .label {
        font-weight: bold;
      }
      input[type="text"],
      input[type="number"],
      select {
        width: 100%;
        height: 25px;
      }
    }
  }
}

.pqn-press-form-dialog {
  max-width: 800px;
  left: calc((100vw - 800px) / 2);
  .modal-dialog {
    max-width: 800px;
  }
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
