.quote-request-data-processing-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
  }
  .items-container {
    .tabs {
      display: flex;
      .tab {
        border: 1px solid #9e9e9e;
        border-right: none;
        border-bottom: none;
        background-color: #bdbdbd;
        padding: 3px 12px;
        cursor: pointer;
        &.active {
          background-color: #fff;
        }
        &:last-child {
          border-right: 1px solid #9e9e9e;
        }
      }
    }
    .items {
      overflow: auto;
      max-height: 65vh;
      border: 1px solid #9e9e9e;
      margin-top: 0;
      background-color: #fafafa;
      padding: 10px;
      .table {
        margin-bottom: 0;
      }
      th,
      td {
        padding: 1px 4px;
        vertical-align: middle;
      }
      .switch-column {
        padding-top: 4px;
        padding-bottom: 0;
        width: 60px;
      }
      .action-column {
        width: 50px;
        text-align: center;
      }
      .fa-question-circle {
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
}

.quote-request-data-processing-dialog {
  max-width: 800px;
  left: calc((100vw - 800px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
