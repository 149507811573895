@import 'components/PqtPagination/PqtPagination.scss';

body {
  background-color: #ffffff;
  font-size: 0.9rem;
  font-family: Roboto, sans-serif;
}

.required {
  color: #dd4b39;
  font-size: 14px;
}

a {
  color: #2196f3;
}

.small-dialog {
  max-width: 500px;
  left: calc((100vw - 500px) / 2);
}

/*.medium-dialog {
  max-width: 800px;
  left: calc((100vw - 800px) / 2);
}

.large-dialog {
  max-width: 1000px;
  left: calc((100vw - 1000px) / 2);
}

.extra-large-dialog {
  max-width: 1200px;
  left: calc((100vw - 1200px) / 2);
}*/

.confirm-alert-container {
  border: 1px solid #ccc;
  padding: 20px 30px;
  background-color: #f6f6f6;
  min-width: 400px;
  h1 {
    font-size: 1rem;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 0.9rem;
      width: 120px;
      height: 30px;
      border: none;
      background-color: #ccc;
      i {
        font-size: 1rem;
      }
    }
  }
}

.large-desktop-only {
  @media (max-width: 1200px) {
    display: none;
  }
}

.quote-module-container {
  .quote-container {
    display: flex;
    flex-wrap: wrap;
    .statistics-container {
      margin-top: 10px;
      flex-basis: 100%;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: bold;
        font-size: 1rem;
      }
    }
    .quote-information-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .quote-information {
        flex-basis: 48%;
        .title-field {
          .label-column {
            width: 85px;
          }
          .input-column {
            width: calc(100% - 85px);
          }
        }
        .po-field {
          .label-column {
            width: 60px;
          }
          .input-column {
            width: calc(100% - 60px);
          }
        }
        .csr-field {
          .label-column {
            width: 85px;
          }
          .input-column {
            width: calc(100% - 85px);
          }
        }
        .sales-field {
          .label-column {
            width: 80px;
          }
          .input-column {
            width: calc(100% - 80px);
          }
        }
        .status-field {
          .label-column {
            width: 60px;
          }
          .input-column {
            width: calc(100% - 60px);
          }
        }
        .turnaround-field {
          .label-column {
            width: 85px;
          }
          .input-column {
            width: calc(100% - 85px);
          }
        }
        .mail-date-field {
          .label-column {
            width: 80px;
          }
          .input-column {
            width: calc(100% - 80px);
          }
        }
        .completion-date {
          margin-left: 15px;
        }
        .add-button {
          margin-top: 10px;
        }
        .error-message {
          color: #c62828;
          font-size: 12px;
        }
      }
      .customer-information {
        flex-basis: 25%;
        button {
          padding: 4px 10px;
          cursor: pointer;
          background-color: #eeeeee;
          border: 1px solid #cfd3d7;
          text-align: center;
          font-size: 14px;
          float: right;
          height: 30px;
          width: 110px;
          margin-top: -30px;
        }
      }
      .quote-log {
        flex-basis: 25%;
      }
      .items-container {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        table {
          border: 1px solid #ccc;
          flex-basis: 100%;
          margin-bottom: 8px;
          th {
            background-color: #0c6db4;
            color: #fff;
            font-weight: normal;
            padding: 2px 5px;
            vertical-align: middle;
            .add-button {
              color: #000;
              font-weight: normal;
              padding: 2px 15px;
              margin-right: 5px !important;
              font-size: 13px;
            }
            &.add {
              padding: 5px;
              width: 125px;
            }
          }
          td {
            padding: 3px 5px;
            &.actions {
              text-align: center;
              font-size: 18px;
              i {
                margin-right: 10px;
                cursor: pointer;
              }
              i:hover {
                color: #0c6db4;
              }
            }
          }
        }
        .printing-estimates-table {
          flex-basis: 100%;
        }
        .mailing-estimates-table {
          flex-basis: 100%;
        }
      }
    }
    .label-column {
      float: left;
      text-align: left;
    }
    .input-column {
      float: left;
      margin-bottom: 10px;
      input,
      select {
        width: 100%;
        height: 25px;
      }
    }
    .card-header {
      padding: 5px 10px;
      background-color: #0c6db4;
      color: #fff;
    }
    .card-body {
      padding: 10px;
    }
  }
}

.order-module-container {
  .order-container {
    display: flex;
    flex-wrap: wrap;
    .statistics-container {
      margin-top: 10px;
      flex-basis: 100%;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: bold;
        font-size: 1rem;
      }
    }
    .order-information-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .order-information {
        flex-basis: 48%;
        .title-field {
          .label-column {
            width: 85px;
          }
          .input-column {
            width: calc(100% - 85px);
          }
        }
        .po-field {
          .label-column {
            width: 60px;
          }
          .input-column {
            width: calc(100% - 60px);
          }
        }
        .csr-field {
          .label-column {
            width: 85px;
          }
          .input-column {
            width: calc(100% - 85px);
          }
        }
        .sales-field {
          .label-column {
            width: 80px;
          }
          .input-column {
            width: calc(100% - 80px);
          }
        }
        .status-field {
          .label-column {
            width: 60px;
          }
          .input-column {
            width: calc(100% - 60px);
          }
        }
        .turnaround-field {
          .label-column {
            width: 85px;
          }
          .input-column {
            width: calc(100% - 85px);
          }
        }
        .mail-date-field {
          .label-column {
            width: 80px;
          }
          .input-column {
            width: calc(100% - 80px);
          }
        }
        .shipping-method-field {
          .label-column {
            width: 60px;
          }
          .input-column {
            width: calc(100% - 60px);
          }
        }
        .completion-date {
          margin-left: 15px;
        }
        .add-button {
          margin-top: 10px;
        }
        .error-message {
          color: #c62828;
          font-size: 12px;
        }
      }
      .customer-information {
        flex-basis: 25%;
        button {
          padding: 4px 10px;
          cursor: pointer;
          background-color: #eeeeee;
          border: 1px solid #cfd3d7;
          text-align: center;
          font-size: 14px;
          float: right;
          height: 30px;
          width: 110px;
          margin-top: 0px;
        }
      }
      .order-log {
        flex-basis: 25%;
      }
      .items-container {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        table {
          border: 1px solid #ccc;
          flex-basis: 100%;
          margin-bottom: 8px;
          th {
            background-color: #0c6db4;
            color: #fff;
            font-weight: normal;
            padding: 2px 5px;
            vertical-align: middle;
            .add-button {
              color: #000;
              font-weight: normal;
              padding: 2px 15px;
              margin-right: 5px !important;
              font-size: 13px;
            }
            &.add {
              padding: 5px;
              width: 125px;
            }
          }
          td {
            padding: 3px 5px;
            &.actions {
              text-align: center;
              font-size: 18px;
              i {
                margin-right: 10px;
                cursor: pointer;
              }
              i:hover {
                color: #0c6db4;
              }
            }
          }
        }
        .printing-estimates-table {
          flex-basis: 100%;
        }
        .mailing-estimates-table {
          flex-basis: 100%;
        }
      }
    }
    .label-column {
      float: left;
      text-align: left;
    }
    .input-column {
      float: left;
      margin-bottom: 10px;
      input,
      select {
        width: 100%;
        height: 25px;
      }
    }
    .card-header {
      padding: 5px 10px;
      background-color: #0c6db4;
      color: #fff;
    }
    .card-body {
      padding: 10px;
    }
  }
}

.estimate-container {
  .estimate-divider {
    border-top: 1px solid #dddddd;
    width: 100%;
    margin-top: 6px;
  }
  label {
    margin-top: 4px;
    font-size: 14px;
  }
  .production-notes {
    font-size: 22px;
    text-align: center;
    color: #d32f2f;
  }
  .col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .card-table {
    td {
      font-size: 14px;
    }
  }
  .add-element-button {
    text-align: right;
    margin-right: 10px;
    font-size: 14px;
    width: 45px;
    float: right;
    cursor: pointer;
  }
  .row {
    margin-bottom: 0;
  }
  .card {
    border: 1px solid #0c6db4;
    .card-header {
      background-color: #0c6db4;
      color: #fff;
    }
    option {
      font-size: 13px;
    }
  }
}

.admin-module-container {
  .card-header {
    background-color: #0c6db4;
    color: #fff;
  }
}

.users-form-container {
  .subtitle {
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 5px;
    .name {
      float: left;
      font-weight: bold;
      font-size: 16px;
    }
    .check-all {
      float: left;
      font-weight: normal !important;
      padding-left: 15px;
    }
  }
  .card-header {
    background-color: #0c6db4;
    color: #fff;
  }
  .card-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .field-container {
      flex-basis: 32%;
      margin-bottom: 5px;
      label {
        margin-top: 0;
      }
    }
    .permission-element {
      margin-top: 0;
      flex-basis: 110px;
    }
    &.secondary-permissions {
      .permission-element {
        margin-top: 0;
        flex-basis: 160px;
      }
    }
  }
  .permissions-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .complete-card {
      flex-basis: 100%;
    }
    .products-card {
      flex-basis: 68%;
      .permission-element {
        flex-basis: 150px;
      }
    }
    .envelopes-card {
      flex-basis: 30%;
      .permission-element {
        flex-basis: 90px;
      }
    }
    .printing-config {
      flex-basis: 26%;
      .permission-element {
        flex-basis: 100px;
      }
    }
    .digital-config {
      flex-basis: 18%;
    }
    .paper-config {
      flex-basis: 52%;
      .permission-element {
        flex-basis: 140px;
      }
    }
    .cutting-config {
      flex-basis: 68%;
    }
    .folding-config {
      flex-basis: 30%;
      .permission-element {
        flex-basis: 140px;
      }
    }
    .bindery-config {
      flex-basis: 100%;
      .permission-element {
        flex-basis: 165px;
      }
    }
    .large-format-config {
      flex-basis: 59%;
    }
    .shrink-wrapping-config {
      flex-basis: 39%;
    }
    .banding-config {
      flex-basis: 32%;
    }
    .wrapping-config {
      flex-basis: 32%;
    }
    .package-config {
      flex-basis: 32%;
    }
    .hand-config {
      flex-basis: 40%;
    }
    .data-config {
      flex-basis: 58%;
      .permission-element {
        flex-basis: 140px;
      }
    }
    .other-mail-card {
      flex-basis: 100%;
      .permission-element {
        flex-basis: 120px;
      }
    }
    .other-card {
      flex-basis: 35%;
      .permission-element {
        flex-basis: 150px;
      }
    }
  }
}

.customers-form-container {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .subtitle {
      margin-top: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 16px;
      flex-basis: 100%;
    }
    .card-header {
      background-color: #0c6db4;
      color: #fff;
      display: flex;
      justify-content: space-between;
      input {
        width: 15px;
      }
    }
    .card-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      label {
        margin-top: 3px;
      }
      .input-container {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        margin-bottom: 2px;
        .label-input {
          flex-basis: 120px;
          text-align: right;
          padding-right: 5px;
        }
        .field-input {
          flex-basis: calc(100% - 120px);
          input {
            width: 100%;
          }
        }
      }
      .state-field {
        flex-basis: 39%;
        .label-input {
          flex-basis: 50px;
        }
        .field-input {
          flex-basis: calc(100% - 50px);
        }
      }
      .city-field {
        flex-basis: 60%;
      }
    }
    .company-information {
      flex-basis: 49%;
    }
    .contact-information {
      flex-basis: 49%;
    }
    .notes {
      flex-basis: 49%;
    }
    .internal-information {
      flex-basis: 49%;
    }
    .billing-information {
      flex-basis: 49%;
    }
    .shipping-information {
      flex-basis: 49%;
    }
  }
}

.presses-form-container {
  form {
    .label-input {
      flex-basis: 140px !important;
    }
    .field-input {
      flex-basis: calc(100% - 140px) !important;
    }
    .costing-information {
      flex-basis: 49%;
    }
    .running-specifications {
      flex-basis: 49%;
    }
    .paper-specifications {
      flex-basis: 100%;
      .label-input {
        flex-basis: 220px !important;
      }
      .field-input {
        flex-basis: calc(100% - 220px) !important;
      }
    }
  }
}

.inks-form-container {
  .label-input {
    flex-basis: 100px !important;
  }
  .field-input {
    flex-basis: calc(100% - 100px) !important;
  }
}

.digital-form-container {
  .costing {
    .label-input {
      flex-basis: 110px !important;
    }
    .field-input {
      flex-basis: calc(100% - 110px) !important;
    }
  }
  .running-specifications {
    .label-input {
      flex-basis: 125px !important;
    }
    .field-input {
      flex-basis: calc(100% - 125px) !important;
    }
  }
  .paper-specifications {
    .label-input {
      flex-basis: 150px !important;
    }
    .field-input {
      flex-basis: calc(100% - 150px) !important;
    }
  }
}

.cutting-form-container {
  .costing {
    flex-basis: 45% !important;
    .label-input {
      flex-basis: 105px !important;
    }
    .field-input {
      flex-basis: calc(100% - 105px) !important;
    }
  }
  .running-specifications {
    flex-basis: 54% !important;
    .label-input {
      flex-basis: 130px !important;
    }
    .field-input {
      flex-basis: calc(100% - 130px) !important;
    }
  }
}

.die-cutting-form-container {
  .costing {
    flex-basis: 45% !important;
    .label-input {
      flex-basis: 105px !important;
    }
    .field-input {
      flex-basis: calc(100% - 105px) !important;
    }
  }
  .running-specifications {
    flex-basis: 54% !important;
    .label-input {
      flex-basis: 130px !important;
    }
    .field-input {
      flex-basis: calc(100% - 130px) !important;
    }
  }
}

.drilling-form-container {
  .costing {
    flex-basis: 45% !important;
    .label-input {
      flex-basis: 105px !important;
    }
    .field-input {
      flex-basis: calc(100% - 105px) !important;
    }
  }
  .running-specifications {
    flex-basis: 54% !important;
    .label-input {
      flex-basis: 130px !important;
    }
    .field-input {
      flex-basis: calc(100% - 130px) !important;
    }
  }
}

.scoring-form-container {
  .costing {
    flex-basis: 45% !important;
    .label-input {
      flex-basis: 105px !important;
    }
    .field-input {
      flex-basis: calc(100% - 105px) !important;
    }
  }
  .running-specifications {
    flex-basis: 54% !important;
    .label-input {
      flex-basis: 130px !important;
    }
    .field-input {
      flex-basis: calc(100% - 130px) !important;
    }
  }
}

.padding-form-container {
  .costing {
    flex-basis: 46% !important;
    .label-input {
      flex-basis: 110px !important;
    }
    .field-input {
      flex-basis: calc(100% - 110px) !important;
    }
  }
  .running-specifications {
    flex-basis: 53% !important;
    .label-input {
      flex-basis: 130px !important;
    }
    .field-input {
      flex-basis: calc(100% - 130px) !important;
    }
  }
}

.customer-discounts-form-container {
  .label-input {
    flex-basis: 60px !important;
  }
  .field-input {
    flex-basis: calc(100% - 60px) !important;
  }
}

.departments-form-container {
  .information {
    flex-basis: 100%;
  }
  .label-input {
    flex-basis: 60px !important;
  }
  .field-input {
    flex-basis: calc(100% - 60px) !important;
  }
}

.employees-form-container {
  .label-input {
    flex-basis: 100px !important;
  }
  .field-input {
    flex-basis: calc(100% - 100px) !important;
  }
}

.product-categories-form-container {
  .label-input {
    flex-basis: 60px !important;
  }
  .field-input {
    flex-basis: calc(100% - 60px) !important;
  }
}

.products-form-container {
  .card {
    flex-basis: 100%;
  }
  .label-input {
    flex-basis: 80px !important;
  }
  .field-input {
    flex-basis: calc(100% - 80px) !important;
  }
}

.form-modal-container {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .subtitle {
      margin-top: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 16px;
      flex-basis: 100%;
    }
    .half-card {
      flex-basis: 49.5%;
    }
    .card-header {
      background-color: #0c6db4;
      color: #fff;
      display: flex;
      justify-content: space-between;
      input {
        width: 15px;
      }
    }
    .card-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      label {
        margin-top: 3px;
      }
      .input-container {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        margin-bottom: 2px;
        .label-input {
          flex-basis: 120px;
          text-align: right;
          padding-right: 2px;
        }
        .field-input {
          flex-basis: calc(100% - 120px);
          input {
            width: 100%;
          }
        }
        &.half-field {
          flex-basis: 50%;
        }
        &.third-field {
          flex-basis: 33%;
        }
      }
    }
  }
}

/*********************** To optimize *************************/
body {
  background-color: #ffffff;
  font-size: 14px;
}

h4 {
  margin-top: 20px;
  margin-bottom: 0;
}
/*a {
  color: #000000;
}*/

.panel-right {
  background-color: #fafafa;
  position: fixed !important;
  top: 0;
  right: 0;
  padding-top: 10px;
  min-height: 100%;
  margin-top: -10px;
  z-index: 1050;
  .box-header {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #bdbdbd;
  }
}

.sidebar-toggle {
  text-decoration: none !important;
}

@media only screen and (max-width: 768px) {
  .panel-right {
    padding-top: 100px;
  }
}

.transition-wrapper {
  position: relative;
}

.vertical-line {
  border-right: 2px dashed #cccccc;
}

.header {
  background-color: #000;
  padding: 5px 10px 10px 15px;
  color: #fff;
}

.btn {
  margin-right: 5px;
}

.alert-dismissible {
  min-width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  .callout {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.btn-add {
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 0;
}

.crud-table {
  margin-right: 10px;
}

.pagination {
  margin-left: 0;
  padding-bottom: 0;
  margin-top: 15px;
}

.content-wrapper {
  background-color: #ffffff;
  .content {
    margin-top: 0;
  }
}

.content-header {
  padding-left: 0;
  padding-bottom: 0;
  small {
    padding-left: 0 !important;
  }
  h2 {
    font-weight: 300;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.search-input {
  margin-left: 10px;
  margin-bottom: 0;
}

.box-body {
  overflow: auto;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px;
}

.box-footer {
  padding-left: 20px;
  padding-top: 15px;
  border-top: 1px solid #eeeeee;
}

.btn-add-row {
  margin-top: 10px;
  float: right;
}

.required {
  color: #dd4b39;
  font-size: 14px;
}

.page-blank {
  background-color: #fff;
}

.color-td {
  padding-left: 50px !important;
}

.color-tr {
  background-color: #008000;
}

.sidebar-menu {
  li {
    a {
      text-decoration: none;
    }
  }
}

.total-items {
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-per-page {
  margin-bottom: 0;
  width: 130px;
}

.information-td {
  padding: 20px !important;
}

.line-header {
  margin-top: 0px;
}

.line-right {
  border-right: 2px solid #d3d3d3;
  border-right-style: dashed;
}

.line-top {
  border-top: 2px solid #d3d3d3;
  border-top-style: dashed;
}

.float-component {
  z-index: 1100;
  position: fixed;
  top: 0;
  right: calc(50% - 50px);
  .box {
    h3 {
      position: absolute;
      margin-left: 10px;
      margin-top: 5px;
    }
    .box-header {
      height: 75px;
      padding: 20px 10px;
      background-color: #ffffff;
    }
    .box-body {
      background-color: #ffffff;
    }
  }
}

.react-draggable,
.handle {
  cursor: move;
}

.no-cursor {
  cursor: auto;
}

.service-number {
  padding-top: 30px;
  font-weight: bold;
}

body {
  font-family: Roboto, sans-serif;
}

.menu-link {
  width: 100%;
  display: block;
  text-decoration: none !important;
  color: #000000;
}

.main-footer {
  padding: 18px 40px;
}

.avatar-email {
  font-size: 14px;
  line-height: 25px;
  padding-left: 15px;
  height: 50px;
}

.konvajs-content {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 15px !important;
}

.menu-item-active {
  background-color: #dddddd !important;
  color: #546e7a !important;
  fill: #546e7a !important;
}

#logo {
  text-align: center;
  img {
    width: 400px;
    margin: auto;
  }
}

/***** New CSS *****/
#header {
  background-color: #ffffff;
  #logo {
    float: left;
    width: 64px;
    margin-top: 10px;
    margin-bottom: 5px;
    img {
      width: 300px;
    }
  }
  #omnimedia-logo {
    float: left;
    width: 100px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .title {
    font-size: 20px;
    float: left;
    margin-left: 20px;
    font-weight: 500;
    margin-top: 35px;
  }
  .title-agent {
    font-size: 20px;
    float: left;
    margin-left: 20px;
    font-weight: 500;
    margin-top: 45px;
  }
  #my-account {
    font-size: 14px;
    float: right;
    margin-top: 40px;
    i {
      margin-right: 15px;
      font-size: 20px;
    }
    a {
      color: #2196f3;
    }
  }
  #my-agent-account {
    font-size: 14px;
    float: right;
    margin-top: 60px;
    i {
      margin-right: 5px;
      font-size: 20px;
    }
    a {
      color: #2196f3;
    }
  }
  #last-updated {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

#admin-header {
  margin-bottom: 25px;
  #section-title {
    font-size: 26px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  .powered {
    font-size: 14px;
    text-align: right;
    margin-top: 20px;
  }
  #dashboard {
    font-size: 18px;
    text-align: right;
    margin-top: 10px;
    a {
      color: #000000;
    }
  }
  .divider {
    border-bottom: 1px solid #000000;
  }
}

.nav-item {
  .nav-link {
    border: 1px solid #eeeeee;
    color: #000000;
    border-radius: 0;
    font-size: 14px;
    border-bottom: none;
    padding: 8px 12px;
  }
  .nav-link:hover {
    background-color: #f5f5f5;
  }
  .nav-link.active {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    font-weight: 500;
    color: #000000;
  }
}

.table-container {
  margin-bottom: 15px;
  .table {
    border: 1px solid #e0e0e0;
    border-top: none;
    font-size: 14px;
    margin-bottom: 10px;
    th {
      border: none !important;
      background-color: #eeeeee;
      padding: 4px;
      font-weight: 500;
      span {
        cursor: pointer;
        i {
          margin-left: 4px;
        }
      }
      .search-input {
        width: 100%;
        margin-left: 0;
        padding: 0 2px;
      }
      select {
        height: 23px;
      }
    }
    th.search-th {
      padding-top: 0;
      .DayPickerInput {
        input {
          width: 100% !important;
          height: 23px;
        }
        input:hover {
          background-color: #ffffff;
        }
      }
    }
    th.multiple-date {
      .DayPickerInput {
        float: left;
        width: calc(50% - 11px) !important;
        input {
          width: 100% !important;
          height: 23px;
        }
        input:hover {
          background-color: #ffffff;
        }
      }
    }
    td {
      padding: 4px;
      line-height: 22px;
    }
    td.editable {
      input {
        width: 100%;
        height: 20px;
      }
      .DayPickerInput {
        input {
          border: none;
          //cursor: pointer;
        }
      }
    }
    td.actions {
      font-size: 18px;
      width: 150px;
      text-align: center;
      i {
        margin-right: 10px;
        cursor: pointer;
      }
      i:hover {
        color: #2196f3;
      }
      #go-action {
        font-size: 14px;
        i {
          font-size: 18px;
          margin-right: 5px;
        }
      }
    }
    tr:hover {
      .DayPickerInput {
        input {
          background-color: #ececec;
        }
      }
    }
  }
  button {
    background-color: #eeeeee;
    border: 1px solid #e0e0e0;
    width: 80px;
    float: right;
    margin-right: 0;
    border-radius: 0;
  }
  .loading-data {
    i {
      margin-top: 4px;
      font-size: 18px;
      margin-right: 4px;
    }
  }
}
.action-button,
.action-button-cancel {
  width: 120px;
  margin-left: 15px;
  border-radius: 0;
}
.action-button {
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
}
.action-button-cancel {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}

.form-container {
  color: #000000;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  .form-field {
    margin-bottom: 10px;
  }
  .error-message {
    font-size: 12px;
    color: #b71c1c;
  }
  input,
  select,
  textarea {
    width: 100%;
    padding: 2px 8px;
    margin-bottom: 2px;
    font-size: 14px;
  }
  select {
    width: 100%;
    padding: 4px 8px;
    margin-bottom: 2px;
    font-size: 14px;
  }
  label {
    margin-top: 8px;
    margin-bottom: 2px;
    font-size: 14px;
  }
  .DayPickerInput {
    width: 100%;
  }
  .loading-data {
    i {
      margin-top: 4px;
      font-size: 18px;
      margin-right: 4px;
    }
  }
  .row {
    margin-bottom: 10px;
  }
  .upload-excel {
    width: 120px;
    background-color: #eeeeee;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    float: left;
  }
  .process-excel {
    width: 120px;
    background-color: #eeeeee;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    float: left;
    margin-left: 5px;
  }
  .progress {
    margin-top: 10px;
    height: 20px;
  }
  .progress-bar {
    color: #ffffff;
  }
  .columns-table {
    margin-top: 15px;
    font-size: 14px;
    overflow: auto;
    max-height: 60vh;
    th {
      font-weight: 500;
      border: 1px solid #e0e0e0;
      padding: 5px;
      text-align: center;
    }
    td {
      border: 1px solid #e0e0e0;
      padding: 3px 10px;
      vertical-align: middle;
      select {
        margin-top: 2px;
      }
      select.empty {
        border: 1px solid #e53935;
        color: #c62828;
      }
      option {
        color: #000000;
      }
    }
  }
  .subtitle {
    margin-left: 12px;
    margin-top: 10px;
    .use-above-address {
      font-size: 14px;
      input {
        width: 15px;
        margin-left: 20px;
      }
    }
  }
}

.hidden {
  display: none !important;
}

.delete-container {
  color: #000000;
  font-size: 15px;
}

.success-notification,
.info-notification,
.danger-notification {
  width: 320px;
  left: calc((100% - 320px) / 2);
  opacity: 0.95;
  position: fixed;
  top: 35px;
  z-index: 1600;
  color: #ffffff;
  border-radius: 3px;
  padding: 10px 10px;
  font-size: 15px;
  box-shadow: 0 0 20px #999;
  .icon {
    float: left;
    i {
      font-size: 24px;
    }
  }
  .text {
    float: left;
    margin-left: 10px;
    margin-top: 2px;
    width: 245px;
  }
}

.success-notification {
  background-color: #4caf50;
}

.info-notification {
  background-color: #2196f3;
}

.danger-notification {
  background-color: #f44336;
}

.pagination-container {
  width: calc(100% - 300px);
  height: 36px;
  float: left;
  ul {
    float: left;
    padding-left: 0;
    list-style-type: none;
    li {
      text-align: center;
      float: left;
      display: block;
      padding: 8px 4px;
      min-width: 40px;
      min-height: 30px;
      border: 1px solid #f5f5f5;
      border-radius: 2px;
      margin-left: 5px;
      font-size: 12px;
      background-color: #f5f5f5;
      cursor: pointer;
    }
    li:hover {
      background-color: #e0e0e0;
      border: 1px solid #e0e0e0;
    }
    li:first-child {
      margin-left: 0;
    }
    li.active {
      background-color: #e0e0e0;
    }
  }
  .total-items {
    margin-left: 15px;
    margin-top: 10px;
    float: left;
    font-size: 12px;
  }
}

#campaign-container {
  .upload-excel {
    width: 100px;
    float: left;
  }
  .export-excel {
    width: 100px;
    float: left;
    margin-left: 10px;
  }
}

#ganging-property-container {
  .upload-excel {
    width: 110px;
    float: right;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .export-excel {
    width: 110px;
    float: right;
    margin-left: 10px;
  }
  .run-algorithm {
    width: 130px;
    float: right;
    margin-left: 10px;
  }
}

#sample-container {
  .upload-excel {
    width: 100px;
    float: right;
  }
  .new-button {
    width: 80px;
    float: right;
    margin-left: 10px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container,
  input {
    width: 100%;
  }
}

.react-datepicker__day--outside-month {
  color: #999999;
}

.file-input-container {
  font-size: 12px;
}

.bold {
  font-weight: 500;
}

#agent-dashboard-container {
  .download-actions {
    a {
      margin-right: 15px;
      color: #333333;
      .fa-download {
        font-size: 16px;
      }
      .fa-file-excel {
        font-size: 18px;
      }
    }
    a:last-child {
      margin-left: 0;
      margin-right: 10px;
    }
  }
  #tables-container {
    padding: 15px;
    border: 1px solid #cfd3d7;
    border-top: none;
  }
  .table-title {
    border: 1px solid #cfd3d7;
    padding: 3px 3px 3px 10px;
    font-size: 15px;
    font-weight: 500;
  }
  .table-container {
    border: 1px solid #cfd3d7;
    border-top: none;
    padding: 10px;
    margin-bottom: 25px;
    .form {
      .form-element {
        width: calc(25% - 56.25px);
        margin-left: 15px;
        margin-bottom: 20px;
        float: left;
        input[type="text"] {
          width: 100%;
        }
      }
      .form-element:first-child {
        margin-left: 0;
      }
      .form-element-check {
        width: 30px;
        margin-left: 15px;
        margin-bottom: 20px;
        margin-top: 5px;
        float: left;
        text-align: center;
      }
      .form-element-button {
        width: 50px;
        margin-left: 25px;
        margin-bottom: 20px;
        margin-top: 5px;
        float: left;
        text-align: center;
        i {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
    .property-check {
      vertical-align: middle;
    }
  }
  .search-container {
    border: 1px solid #cfd3d7;
    margin-bottom: 15px;
    float: left;
    padding: 10px;
    .search-title {
      margin-bottom: 15px;
    }
    .fa-search {
      font-size: 20px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .search-property-container {
    padding: 10px;
    .search-title {
      margin-bottom: 9px;
    }
  }
  #search-field {
    width: calc(36% - 10px);
    padding-bottom: 17px;
    input[type="text"] {
      width: calc(100% - 30px);
    }
    #search-property {
      float: left;
      width: calc(100% - 30px);
      height: 26px;
      option {
        color: #000000;
      }
    }
    #search-property.selected {
      color: #000000;
    }
    #types {
      float: left;
      margin-left: 15px;
      width: 110px;
      margin-top: -20px;
    }
  }
  #search-phone {
    width: calc(32% - 10px);
    margin-left: 15px;
    .mini-input {
      width: 30px;
      text-align: center;
    }
    .small-input {
      width: 40px;
      text-align: center;
    }
  }
  #search-select {
    width: calc(32% - 10px);
    margin-left: 15px;
    input[type="text"] {
      width: calc(100% - 30px);
    }
    select {
      width: calc(100% - 30px);
      height: 26px;
      option {
        color: #000000;
      }
    }
    select.selected {
      color: #000000;
    }
  }
  #agent-data {
    width: 350px;
    padding-bottom: 10px;
  }
  #download-template {
    float: right;
    padding: 10px;
    text-align: center;
    background-color: #eeeeee;
    border: 1px solid #cfd3d7;
    cursor: pointer;
  }
  #submit-request-container {
    float: right;
    padding: 10px;
    cursor: pointer;
    background-color: #eeeeee;
  }
  .map-container {
    border: 1px solid #cfd3d7;
    margin-top: 10px;
    text-align: center;
  }
  .submit-request-button {
    width: 150px;
    text-align: center;
  }
  .upload-icon,
  .download-icon {
    cursor: pointer;
  }
  .add-button {
    float: right;
    padding: 3px 10px;
    cursor: pointer;
    width: 80px;
    background-color: #eeeeee;
    border: 1px solid #cfd3d7;
    text-align: center;
    margin-bottom: 15px;
    margin-right: 0;
  }
}

#admin-dashboard-container {
  .download-actions {
    a {
      margin-right: 15px;
      color: #333333;
      .fa-download {
        font-size: 16px;
      }
      .fa-file-excel {
        font-size: 18px;
      }
    }
    a:last-child {
      margin-left: 0;
      margin-right: 10px;
    }
  }
  .table-title {
    border: 1px solid #cfd3d7;
    padding: 3px 3px 3px 10px;
    font-size: 15px;
    font-weight: 500;
  }
  .table-container {
    border: 1px solid #cfd3d7;
    border-top: none;
    padding: 10px;
    margin-bottom: 25px;
    .form {
      .form-element {
        width: calc(25% - 56.25px);
        margin-left: 15px;
        margin-bottom: 20px;
        float: left;
        input[type="text"] {
          width: 100%;
        }
      }
      .form-element:first-child {
        margin-left: 0;
      }
      .form-element-check {
        width: 30px;
        margin-left: 15px;
        margin-bottom: 20px;
        margin-top: 5px;
        float: left;
        text-align: center;
      }
      .form-element-button {
        width: 50px;
        margin-left: 25px;
        margin-bottom: 20px;
        margin-top: 5px;
        float: left;
        text-align: center;
        i {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
  .search-container {
    border: 1px solid #cfd3d7;
    margin-bottom: 15px;
    float: left;
    padding: 10px;
    .search-title {
      margin-bottom: 15px;
    }
    .fa-search {
      font-size: 20px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .search-property-container {
    padding: 10px;
    .search-title {
      margin-bottom: 9px;
    }
  }
  #search-field {
    width: calc(36% - 10px);
    padding-bottom: 17px;
    input[type="text"] {
      width: calc(100% - 30px);
    }
    #search-property {
      float: left;
      width: calc(100% - 30px);
      height: 26px;
      option {
        color: #000000;
      }
    }
    #search-property.selected {
      color: #000000;
    }
    #types {
      float: left;
      margin-left: 15px;
      width: 110px;
      margin-top: -20px;
    }
  }
  #search-phone {
    width: calc(32% - 10px);
    margin-left: 15px;
    .mini-input {
      width: 30px;
      text-align: center;
    }
    .small-input {
      width: 40px;
      text-align: center;
    }
  }
  #search-select {
    width: calc(32% - 10px);
    margin-left: 15px;
    input[type="text"] {
      width: calc(100% - 30px);
    }
    select {
      width: calc(100% - 30px);
      height: 26px;
      option {
        color: #000000;
      }
    }
    select.selected {
      color: #000000;
    }
  }
  #download-template {
    float: right;
    padding: 10px;
    text-align: center;
    background-color: #eeeeee;
    border: 1px solid #cfd3d7;
    cursor: pointer;
  }
  #submit-request-container {
    float: right;
    padding: 10px;
    cursor: pointer;
    background-color: #eeeeee;
  }
  .submit-request-button {
    width: 150px;
    text-align: center;
  }
  .upload-icon,
  .download-icon {
    cursor: pointer;
  }
  #search-market-title {
    float: left;
  }
  #caps-button {
    float: right;
    padding: 3px 10px;
    cursor: pointer;
    background-color: #eeeeee;
    border: 1px solid #cfd3d7;
    text-align: center;
    margin-top: -4px;
    i {
      margin-left: 0 !important;
    }
  }
  .button {
    padding: 3px 10px;
    cursor: pointer;
    background-color: #eeeeee;
    border: 1px solid #cfd3d7;
    text-align: center;
    margin-bottom: 15px;
  }
  .add-button {
    margin-right: 10px;
  }
  .production-container {
    .add-button {
      cursor: pointer;
      float: left;
    }
    .add-button:hover {
      text-decoration: underline;
    }
    th,
    td {
      font-size: 14px;
    }
    .list-field {
      input {
        width: calc(100% - 30px) !important;
      }
    }
    td.property {
      i {
        cursor: pointer;
      }
      i:hover {
        color: #2196f3;
      }
      input {
        width: calc(100% - 18px) !important;
      }
    }
    .first-drop-date {
      input {
        border: none;
        background-color: #f4f4f4;
        cursor: pointer;
      }
    }
    #market-title-table {
      border: none;
      margin-bottom: 0;
      .market-title {
        font-weight: 500;
        font-size: 18px;
      }
      i {
        font-size: 18px;
      }
      td {
        border: none;
      }
      .add-button {
        cursor: pointer;
        float: right;
        margin-top: -20px;
      }
      .add-button:hover {
        text-decoration: underline;
      }
    }
    #market-data-table {
      border: none;
      td {
        background-color: #f4f4f4;
        border: none;
        border-bottom: 1px solid #ffffff;
        .fa-circle.success {
          color: #4caf50;
        }
        .fa-circle.danger {
          color: #f44336;
        }
      }
      tr.blank {
        td {
          background-color: #ffffff;
        }
      }
      input[type="text"] {
        height: 22px;
        width: 100%;
        margin-top: 2px;
      }
      input[type="checkbox"] {
        margin-top: 6px;
        margin-left: 5px;
      }
      select {
        height: 22px;
        width: 100%;
        margin: 2px;
      }
    }
    .filter {
      float: left;
      margin-bottom: 10px;
      input[type="checkbox"] {
        margin-left: 20px;
      }
    }
    .selects {
      margin-left: 30px;
      float: left;
      select {
        margin-left: 5px;
        margin-right: 15px;
        width: 140px;
      }
    }
    .notes {
      width: calc(100% - 55px);
      float: left;
    }
    .actions {
      float: left;
      text-align: center;
      .action-tooltip {
        margin-right: 15px;
        margin-top: 3px;
        i {
          font-size: 18px;
          cursor: pointer;
        }
        i:hover {
          color: #2196f3;
        }
      }
    }
  }
  .save-button {
    padding: 4px 20px;
    cursor: pointer;
    background-color: #eeeeee;
    border: 1px solid #cfd3d7;
    text-align: center;
    margin-bottom: 15px;
    float: right;
    font-size: 14px;
    margin-top: -40px;
    width: 100px;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .upload-button {
    padding: 4px 20px;
    cursor: pointer;
    background-color: #eeeeee;
    border: 1px solid #cfd3d7;
    text-align: center;
    margin-bottom: 15px;
    float: right;
    font-size: 14px;
    margin-top: -40px;
    margin-left: 10px;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .caps-container {
    .save-button {
      margin-top: -5px;
    }
    .upload-button {
      margin-top: -5px;
    }
  }
}

.generate-forms-container {
  font-size: 14px;
  color: #000000;
  .select-label {
    margin-bottom: 10px;
    font-size: 15px;
  }
  .results-container {
    margin-top: 15px;
    max-height: 400px;
    overflow: auto;
  }
  .select-button {
    width: 80px;
    border-radius: 0;
    background-color: #e0e0e0;
    border: 1px solid #e0e0e0;
    margin: 0;
  }
}

.ganging-tabs {
  margin-bottom: 9px;
  margin-top: -5px;
}

.ganging-actions {
  margin-top: -50px;
}

.ganging-run-container {
  .upload-button {
    margin-top: 0 !important;
  }
  #tables-container {
    border: 1px solid #cfd3d7 !important;
    margin-top: -10px;
  }
  #first-column {
    width: calc(30% - 5px) !important;
    .table-container {
      height: calc(55vh - 188px) !important;
    }
  }
  #second-column {
    width: calc(70% - 5px) !important;
    .table-container {
      height: calc(55vh - 188px) !important;
    }
  }
  .run-button {
    width: 105px;
  }
  #result-info {
    margin-top: 10px;
    .table-container {
      margin-bottom: 0 !important;
      height: calc(45vh - 15px) !important;
      overflow: auto;
    }
    .result-form {
      margin-bottom: 10px;
      h6 {
        font-size: 16px;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
    .form-summary {
      float: left;
      width: 200px;
    }
    .form-jobs {
      float: left;
      width: calc(100% - 200px);
    }
    .summary {
      font-size: 14px;
    }
  }
  .fa-info-circle {
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  .fa-info-circle:hover {
    color: #2196f3 !important;
  }
  .fa-info-circle.selected {
    color: #1c81d1;
  }
  .costs {
    float: right;
    margin-top: 3px;
    font-size: 13px;
    input {
      width: 50px;
      margin-right: 5px;
      margin-left: 2px;
    }
  }
  .filter {
    float: left;
    margin-bottom: 10px;
  }
}

.properties-container {
  font-size: 14px;
  margin-top: 10px;
  overflow: auto;
  height: 50vh;
  .properties-title {
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
  }
  .market-title {
    font-weight: 500;
    margin-top: 5px;
  }
  .seed-property {
    float: left;
    width: 170px;
    margin-right: 5px;
  }
  input[type="checkbox"] {
    width: 15px;
  }
}

.permissions-container {
  margin-top: 10px;
  font-size: 14px;
  .title {
    font-weight: 500;
    font-size: 15px;
  }
  input[type="checkbox"] {
    width: 14px;
    margin-left: 15px;
  }
}

.add-test-zip-code-button {
  float: right;
  padding: 3px 10px;
  cursor: pointer;
  width: 80px;
  background-color: #eeeeee;
  border: 1px solid #cfd3d7;
  text-align: center;
  margin-bottom: 15px;
  margin-right: 0;
}

.clean-button {
  padding: 3px 10px;
  cursor: pointer;
  background-color: #eeeeee;
  border: 1px solid #cfd3d7;
  text-align: center;
  margin-bottom: 15px;
  width: 140px;
  float: right;
}

.card-header {
  font-weight: 500;
  font-size: 14px;
  padding: 6px 20px;
}

.add-button {
  padding: 4px 20px;
  cursor: pointer;
  background-color: #eeeeee;
  border: 1px solid #cfd3d7;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
}

.form-table {
  width: 100%;
  .labelColumn {
    text-align: right;
  }
  .inputColumn {
    input {
      float: left;
      width: 250px;
      height: 26px;
      padding: 2px 4px;
      margin-left: 4px;
    }
    textarea {
      float: left;
      width: 380px;
      height: 100px;
      padding: 2px 4px;
      margin-left: 4px;
      margin-bottom: 2px;
    }
    select {
      width: 250px;
      height: 26px;
      float: left;
      margin-left: 4px;
    }
    .error-message {
      float: left;
      margin-left: 5px;
      font-size: 12px;
      color: #e53935;
    }
  }
}

#estimate-pricing-table {
  font-size: 14px;
  margin-bottom: 0;
  th {
    font-weight: 500;
    border-top: none;
  }
  td {
    border-top: none;
  }
  .quantity-field {
    width: 100%;
  }
  .quantity-td {
    width: 80px;
    text-align: right;
    border-right: 1px solid #999;
    input {
      padding: 0 4px;
    }
  }
  .quantity-td:last-child {
    border-right: none;
  }
  .price-td {
    width: 95px;
    text-align: right;
    padding-right: 5px;
    border-left: 1px solid #999;
    input {
      padding: 0 4px;
      width: calc(100% - 20px);
      text-align: right;
    }
  }
  .estimates-table {
    td {
      border-top: 0;
    }
  }
  .cost-td {
    width: 85px;
    text-align: right;
    padding-right: 5px;
    border-left: 1px solid #999;
  }
}

#order-container {
  #estimates-table {
    border: 0;
    td {
      border-top: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 10px;
    }
    td.artworks {
      text-align: left;
      i {
        font-size: 18px;
        cursor: pointer;
      }
      i:hover {
        color: #2196f3;
      }
    }
    td.approval {
      select {
        width: 120px;
      }
    }
    td.price {
      width: 10%;
      text-align: right;
    }
    td.actions {
      width: 110px;
      text-align: center;
    }
  }
}

.imposition-name {
  font-size: 14px;
}

.loading {
  text-align: center;
  font-size: 15px;
  margin-top: 20px;
  i {
    font-size: 22px;
  }
}

.header-action {
  margin-top: 1px;
  i {
    font-size: 18px;
    cursor: pointer;
  }
  i:hover {
    color: #2196f3;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f4f4f4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6d6d6d;
}

.admin-icon {
  width: 82px;
  margin-right: 15px;
  float: left;
  cursor: pointer;
  i {
    font-size: 32px;
  }
}
.admin-icon:hover {
  color: #2196f3;
}

.data-table {
  margin-bottom: 5px;
  th {
    font-size: 14px;
    font-weight: 500;
  }
  td {
    font-size: 14px;
    .action-icon {
      i {
        font-size: 18px;
        cursor: pointer;
      }
      i:hover {
        color: #2196f3;
      }
    }
  }
}

.active-papers {
  font-weight: 500;
  .badge {
    font-size: 13px;
    .fa-times {
      font-size: 14px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.active-presses {
  font-weight: 500;
  .badge {
    font-size: 13px;
    .fa-times {
      font-size: 14px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.accessory-checkbox {
  font-size: 14px;
  margin-right: 10px;
  input[type="checkbox"] {
    width: 15px;
  }
}

.files-list {
  font-size: 14px;
  margin-top: 10px;
}

.artworks {
  ol {
    padding-left: 16px;
  }
  .artwork-link {
    cursor: pointer;
    text-align: left;
    float: left;
    margin-right: 15px;
    a {
      color: #2196f3;
    }
    .fa-trash {
      margin-left: 5px;
    }
    .float-trash {
      margin-left: 5px;
      position: absolute;
      margin-left: -20px;
      margin-top: 5px;
    }
    .thumbnail {
      border: 1px solid #716d6d;
      margin-bottom: 10px;
    }
  }
  .artwork-li {
    cursor: pointer;
    text-align: left;
    a {
      color: #2196f3;
      margin-right: 5px;
    }
  }
}

.artwork-link:hover {
  text-decoration: underline;
}

.tippy-popper {
  max-width: 800px;
}
.tooltip-column {
  margin-right: 15px;
}

.add-outside-services {
  font-size: 14px;
  cursor: pointer;
  float: right;
}
.add-outside-services:hover {
  text-decoration: underline;
}

#outside-services-table {
  font-size: 14px;
  th {
    font-weight: normal;
  }
  td {
    i {
      cursor: pointer;
    }
  }
}

.dashboard-container {
  background-color: rgba(0, 0, 0, 0.125);
  padding-bottom: 0 !important;
  .section-title {
    font-size: 18px;
  }
  .dashboard-card {
    width: 230px;
    float: left;
    .title {
      font-size: 14px;
      color: #455a64;
      text-align: center;
    }
    .content {
      text-align: center;
      font-size: 28px;
      font-family: "Open Sans", sans-serif;
    }
  }
}

.loading-icon {
  position: absolute;
  right: 34px;
  margin-top: -28px;
}

.top-loading-icon {
  position: absolute;
  right: 34px;
}

.inout-table-title {
  border: 1px solid #cfd3d7;
  padding: 3px 3px 3px 10px;
  font-size: 18px;
  font-weight: 500;
}

.user-notification {
  float: left;
  width: 32%;
  font-size: 14px;
  input[type="checkbox"] {
    width: 14px;
    margin-right: 2px;
  }
}

.attached-file {
  font-size: 14px;
  margin-top: 10px;
}

.divider-in-out {
  border-top: 2px solid #333333;
  margin-bottom: 10px;
  margin-top: -10px;
}

.in-out-role-name {
  margin-top: 10px;
  font-weight: bold;
}

#deposits-form {
  .element {
    float: left;
    margin-right: 15px;
    input[type="text"],
    textarea,
    select {
      width: 100%;
    }
    input[type="text"],
    select {
      height: 25px;
    }
  }
  .column1 {
    width: 50%;
    float: left;
    margin-top: 15px;
  }
  .column2 {
    width: 12%;
    float: left;
    margin-top: 15px;
    .pm-input {
      margin-bottom: 5px;
    }
  }
  .column3 {
    width: 38%;
    float: left;
    margin-top: 15px;
  }
  .audit-element {
    margin-top: 10px;
    font-size: 13px;
    color: #666666;
    .changed {
      color: #d32f2f;
    }
  }
}

tr.edited {
  td {
    color: #d32f2f;
  }
}

tr.noticed {
  td {
    color: #388e3c;
  }
}

.deposit-email-element {
  font-size: 14px;
  margin-top: 5px;
  float: left;
  width: 200px;
  input[type="checkbox"] {
    width: 14px;
  }
}

.permission-element {
  font-size: 14px;
  float: left;
  width: 230px;
  margin-top: 10px;
  input[type="checkbox"] {
    width: 14px;
  }
}

#check-all {
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  margin-top: 5px;
  input[type="checkbox"] {
    width: 14px;
  }
}

.DayPickerInput {
  input {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #666666;
  }
}

#order-container {
  #order-information {
    label {
      margin-bottom: 0;
    }
    input,
    select {
      width: 100%;
      height: 25px;
    }
    textarea {
      width: 100%;
    }
    .inputColumn {
      height: 25px;
      margin-bottom: 15px;
    }
    .add-button {
      margin-top: 40px;
      width: 100px;
    }
    .error-message {
      font-size: 12px;
      color: #b71c1c;
      margin-bottom: 10px;
    }
  }
  #customer-information {
    select {
      height: 25px;
      min-width: 150px;
    }
  }
}

#due-date {
  .DayPickerInput {
    width: 100%;
  }
}

#forgot-password {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  span {
    cursor: pointer;
    color: #2196f3;
  }
  span:hover {
    text-decoration: underline;
  }
}

#change-order-form {
  .element {
    float: left;
    margin-right: 15px;
    input[type="text"],
    textarea,
    select {
      width: 100%;
    }
    input[type="text"],
    select {
      height: 25px;
    }
  }
  .audit-element {
    margin-top: 10px;
    font-size: 13px;
    color: #666666;
    .changed {
      color: #d32f2f;
    }
  }
  #audit-history {
    .title {
      font-weight: bold;
    }
  }
  .costs {
    td,
    th {
      padding: 4px 2px;
    }
  }
  .user-notification {
    width: 25%;
  }
  .in-out-role-name {
    margin-top: 5px;
  }
  .DayPickerInput {
    width: 100%;
  }
}

.audit-element {
  margin-top: 10px;
  font-size: 13px;
  color: #666666;
  .changed {
    color: #d32f2f;
  }
}

.remove-mailing-tooltip {
  i {
    font-size: 18px;
    cursor: pointer;
  }
  i:hover {
    color: #2196f3;
  }
}

.discount-table {
  margin-top: 15px;
  font-size: 14px;
  td {
    i {
      cursor: pointer;
    }
    i:hover {
      color: #d32f2f;
    }
  }
}

#configuration-container {
  .add-button {
    float: right !important;
    margin-bottom: 0;
    margin-right: 0;
  }
  .add-button:hover {
    text-decoration: none !important;
  }
  .configuration-field {
    width: calc(100% - 135px);
    float: left;
  }
  .show-field-label {
    width: 40px;
    float: left;
    margin-left: 5px;
    margin-top: 3px;
  }
  .show-field {
    width: 60px;
    float: left;
  }
  .lock {
    margin-left: 9px;
    font-size: 18px;
    margin-top: -1px;
    float: left;
    .fa-lock,
    .fa-lock-open {
      cursor: pointer;
      &:hover {
        color: #2196f3;
      }
    }
  }
  .accessories-lock {
    font-size: 18px;
    position: absolute;
    margin-top: -30px;
    margin-left: 128px;
    i {
      cursor: pointer;
      &:hover {
        color: #2196f3;
      }
    }
  }
}

.timeline-form {
  font-size: 14px;
  .subtitle {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .column1 {
    float: left;
    width: 30%;
    padding-right: 5px;
    .completed-field {
      text-align: center;
      margin-top: 10px;
      input {
        width: 15px;
      }
    }
  }
  .column2 {
    float: left;
    width: 70%;
    input[type="checkbox"] {
      width: 15px;
    }
    .other-check-field {
      width: 133px;
    }
  }
  .label {
    float: left;
  }
  .form-input {
    float: left;
    margin-left: 5px;
    width: 90%;
  }
  .notes-container {
    width: 733px;
    margin-top: 20px;
    textarea {
      height: 220px;
    }
  }
}

.timeline-logs-table {
  .DayPickerInput {
    width: 100% !important;
  }
  .expired {
    color: red;
  }
}

#restore-container {
  form {
    width: 350px;
    margin: 30px auto auto;
    background-color: #f5f5f5;
    border: 1px solid #cfd3d7;
    padding: 15px 30px 20px;
  }
  h4 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  input {
    width: 100%;
    margin-bottom: 10px;
  }
  label {
    margin-bottom: 0;
  }
}

.employee-departments {
  margin-top: 15px;
  input[type="checkbox"] {
    width: 20px;
  }
}
