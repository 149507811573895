.chat-room-container {
  border: 1px solid #009688;
  height: 80vh;
  &.large {
    width: calc(1200px - 350px);
    @media (max-width: 1200px) {
      width: calc(1000px - 300px);
    }
  }
  &.small {
    width: calc(1200px - 550px);
    @media (max-width: 1200px) {
      width: calc(1000px - 500px);
    }
  }
  .title {
    background-color: #009688;
    padding: 8px 10px;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    i {
      margin-right: 20px;
      cursor: pointer;
      font-size: 1rem;
    }
    i:last-child {
      margin-right: 8px;
    }
  }
  .messages {
    background-color: #fcfcfc;
    height: calc(80vh - 89px);
    overflow: auto;
    font-size: 0.85rem;
    padding: 5px;
    .message-element-container {
      display: flex;
      &.mine {
        justify-content: flex-end;
      }
      &.other {
        justify-content: flex-start;
      }
      .message-element {
        margin-left: 10px;
        margin-right: 5px;
        margin-top: 8px;
        max-width: 85%;
        border-radius: 5px;
        padding: 3px 8px;
        &.mine {
          background-color: #dcf8c6;
        }
        &.other {
          background-color: #eeeeee;
        }
        .user-name {
          font-size: 0.8rem;
          font-weight: bold;
        }
        .message-date {
          font-size: 0.75rem;
          text-align: right;
        }
      }
      &:last-child {
        margin-bottom: 10px;
      }
    }
    .load-more-messages {
      text-align: center;
      background-color: #c7d9d7;
      padding: 6px;
      margin-top: 5px;
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 15px;
      cursor: pointer;
      font-size: 0.8rem;
      &.hide {
        visibility: hidden;
      }
    }
    &.messages-loading {
      padding-left: 10px;
      padding-top: 10px;
    }
  }
  .new-message {
    background-color: #eeeeee;
    height: 50px;
    padding: 11px;
    input {
      width: calc(100% - 40px);
      border-radius: 10px;
      border: none;
      padding: 2px 25px 2px 8px;
      font-size: 0.85rem;
      height: 28px;
    }
    .fa-paper-plane {
      font-size: 20px;
      color: #9e9e9e;
      margin-left: 10px;
      cursor: pointer;
    }
    .fa-circle-notch {
      position: absolute;
      margin-top: 6px;
      margin-left: -22px;
      font-size: 16px;
    }
  }
  .select-chat {
    text-align: center;
    margin-top: 50px;
    font-size: 1.1rem;
    line-height: 2.5rem;
  }
  .close {
    position: absolute;
    right: 15px;
    top: 10px;
    i {
      font-size: 1rem;
      font-weight: bold;
      margin-right: 8px;
      cursor: pointer;
      color: #000;
    }
  }
}
