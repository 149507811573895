.upload-data-container {
  padding: 10px;
  border: 1px solid #333;
  margin-top: -1px;
  margin-bottom: 15px;
  .file-container {
    background-color: #fafafa;
    border: 1px solid #444;
    width: 360px;
    margin: auto;
    padding: 15px;
    font-size: 0.9rem;
    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 4px;
      text-align: center;
    }
    .name-field {
      margin-bottom: 8px;
      input {
        width: 100%;
        height: 24px;
      }
    }
    .posted-date-field {
      margin-bottom: 8px;
      .DayPickerInput {
        width: 100%;
        input {
          height: 24px;
        }
      }
    }
    .select-file-container {
      margin-top: 2px;
      .dropzone-container {
        width: 180px;
        border: 1px solid #bdbdbd;
        background-color: #e0e0e0;
        cursor: pointer;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 2px;
        margin-bottom: 3px;
      }
    }
    .buttons-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      button {
        width: 110px;
        margin-top: 2px;
        border: 1px solid #bdbdbd;
        background-color: #e0e0e0;
        height: 25px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .processed-data-container {
    margin-top: 25px;
    .account-field {
      width: 180px;
    }
    .save-items-button {
      width: 110px;
      margin-top: 2px;
      border: 1px solid #ad1457;
      background-color: #e91e63;
      color: #fff;
      height: 25px;
      text-align: center;
      cursor: pointer;
      position: absolute;
      right: 20px;
      margin-top: -35px;
    }
  }
}
