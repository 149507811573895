.matrix-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
    text-align: center;
  }
}

.matrix-dialog {
  max-width: 800px;
  left: calc((100vw - 800px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
