.chat-container {
  @import "ChatRoom/ChatRoom.scss";
  @import "ChatIcon/ChatIcon.scss";
  @import "ChatList/ChatList.scss";
  @import "ChatMembers/ChatMembers.scss";
  .main-chat {
    background-color: #eceff1;
    position: fixed;
    display: flex;
    top: 10vh;
    height: 80vh;
    box-shadow: 0 0 20px #333;
    z-index: 100;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    left: calc((100vw - 1200px) / 2);
    width: 1200px;
    @media (max-width: 1200px) {
      left: calc((100vw - 1000px) / 2);
      width: 1000px;
    }
    .chat-list {
      &.hide {
        visibility: hidden;
      }
    }
    .chat-room {
      &.hide {
        visibility: hidden;
      }
    }
    .chat-members {
      &.hide {
        visibility: hidden;
      }
    }
    &.hide {
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;
    }
  }
  .chat-icon {
    top: 12px;
    right: 165px;
    position: absolute;
    &.hide {
      visibility: hidden;
    }
  }
}
