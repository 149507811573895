.push-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
    color: #00796b;
  }
  h2 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .warning {
    margin-top: 5px;
    border: 2px solid #009688;
    border-left: 8px solid #009688;
    padding: 8px;
    border-radius: 3px;
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  select {
    width: 100%;
  }
}

.push-dialog {
  max-width: 420px;
  left: calc((100vw - 420px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
  .vendor-email-field {
    margin-top: 15px;
    input[type="text"] {
      margin-left: 3px;
      height: 22px;
      width: 100%;
    }
  }
}
