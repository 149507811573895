.report-container {
  padding: 10px;
  border: 1px solid #333;
  margin-top: -1px;
  margin-bottom: 15px;
  table {
    border: 1px solid #e0e0e0;
    border-top: none;
    font-size: 14px;
    th {
      background-color: #9e9e9e;
      border: none;
      padding: 4px;
      font-weight: 500;
    }
    td {
      // border: none;
      padding: 1px 4px;
      &.price {
        text-align: right;
      }
    }
    .totals {
      td {
        padding-bottom: 15px;
        &.red {
          color: #e53935;
        }
      }
    }
  }
  .action-buttons {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .export-button {
      width: 120px;
      background-color: #e0e0e0;
      border: 1px solid #e0e0e0;
      a {
        color: #000;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .report-button {
      width: 120px;
      background-color: #e0e0e0;
      border: 1px solid #e0e0e0;
      margin-right: 10px;
      a {
        color: #000;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .postage-button {
      width: 200px;
      background-color: #e0e0e0;
      border: 1px solid #e0e0e0;
      margin-left: 10px;
      margin-right: 10px;
      a {
        color: #000;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .select-report {
    height: 25px;
    margin-left: 4px;
    margin-right: 20px;
  }
  .select-customer {
    width: 180px;
    margin-left: 4px;
    margin-right: 20px;
  }
  .select-sales-rep {
    width: 100px;
    margin-left: 4px;
    margin-right: 20px;
  }
  .DayPickerInput {
    width: 90px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .action-tooltip {
    margin-right: 20px;
  }
  .fa-calendar-times {
    font-size: 1.05rem;
    cursor: pointer;
  }
}
