.search-container {
  margin-top: -1px;
  margin-bottom: 15px;
  .search-field {
    display: flex;
    justify-content: flex-end;
    input[type="text"] {
      height: 24px;
      width: 300px;
    }
    button {
      background-color: #eeeeee;
      border: 1px solid #bdbdbd;
      padding: 2px 20px;
      margin-top: -2px;
      margin-left: 10px;
      width: 80px;
    }
  }
  .checkboxes {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    margin-right: 90px;
    input[type="checkbox"] {
      margin-right: 2px;
      margin-left: 25px;
    }
  }
  @import 'ActiveResults/ActiveResults.scss';
  @import 'FinishedResults/FinishedResults.scss';
}
