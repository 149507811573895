.form-container {
  width: 1300px;
  margin: auto;
  overflow: inherit;
  max-height: inherit;
  .tabs-container {
    display: flex;
    justify-content: space-between;
    .tabs {
      display: flex;
      .tab {
        border: 1px solid #9e9e9e;
        border-right: none;
        border-bottom: none;
        background-color: #bdbdbd;
        padding: 5px 15px;
        cursor: pointer;
        &.active {
          background-color: #fff;
        }
        &:last-child {
          border-right: 1px solid #9e9e9e;
        }
      }
    }
    .relations-container {
      align-items: center;
      font-weight: bold;
      font-size: 0.95rem;
      display: flex;
      .link {
        margin-left: 20px;
      }
    }
  }
  .content {
    border: 1px solid #9e9e9e;
    margin-top: 0;
    background-color: #fafafa;
  }
  select,
  input[type="text"],
  input[type="number"] {
    height: 24px;
    padding: 2px 4px;
  }
  input[type="checkbox"],
  input[type="radio"] {
    width: 20px;
  }
  .information-form-container {
    .line1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .customer-field {
        flex-basis: 25%;
      }
      .csr-field {
        flex-basis: 15%;
      }
      .due-date-field {
        flex-basis: 10%;
      }
      .product-type-field {
        flex-basis: 16%;
      }
      .process-request-field {
        flex-basis: 15%;
      }
      .artwork-required-field {
        flex-basis: 11%;
      }
      .DayPickerInput {
        input {
          height: 24px;
        }
      }
    }
    .line2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 70%;
        border-right: 1px solid #333;
        padding-right: 15px;
        .title {
          margin-bottom: 8px;
        }
        .checkboxes {
          display: flex;
          flex-wrap: wrap;
          .check-field {
            flex-basis: 145px;
          }
        }
      }
      .column2 {
        flex-basis: 30%;
        padding-left: 15px;
        .title {
          margin-bottom: 8px;
        }
        .checkboxes {
          display: flex;
          flex-wrap: wrap;
          .check-field {
            flex-basis: 70px;
          }
          .repeat-field {
            flex-basis: 228px;
            input[type="text"] {
              width: 80px;
            }
          }
        }
      }
    }
    .line3 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 70%;
        border-right: 1px solid #333;
        padding-right: 15px;
      }
      .column2 {
        flex-basis: 30%;
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        .quantity-field {
          flex-basis: 30%;
        }
      }
    }
    .line4 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 42%;
        border-right: 1px solid #333;
        padding-right: 15px;
        textarea {
          height: 130px;
        }
      }
      .files-container {
        flex-basis: 28%;
        padding-left: 15px;
        border-right: 1px solid #333;
        padding-right: 15px;
        th {
          border: 1px solid #333;
          background-color: #bdbdbd;
          padding: 2px 5px;
        }
        td {
          border: 1px solid #333;
          padding: 2px 5px;
          i {
            cursor: pointer;
          }
        }
        .browse-files-button {
          margin-left: 15px;
          background-color: #bdbdbd;
          border: 1px solid #9e9e9e;
          padding: 2px 20px;
        }
        h3 {
          font-size: 0.9rem;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .column2 {
        flex-basis: 30%;
        padding-left: 15px;
        .title {
          display: flex;
          justify-content: space-between;
        }
        .options {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        textarea {
          height: 100px;
        }
      }
    }
  }
  .flat-form-container {
    .line1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 30%;
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .originals-field {
          flex-basis: 30%;
        }
        .product-type-container {
          flex-basis: 50%;
          .product-type {
            margin-bottom: 8px;
          }
        }
      }
      .column2 {
        flex-basis: 70%;
        padding-left: 15px;
        .title {
          color: #1976d2;
        }
        .subline1 {
          margin-bottom: 10px;
        }
        .subline1,
        .subline2 {
          display: flex;
          justify-content: space-between;
          .subtitle {
            margin-top: 22px;
            flex-basis: 5%;
            margin-left: 0;
          }
          .colors-field {
            flex-basis: 15%;
          }
          .ink-field {
            flex-basis: 20%;
          }
          .pms-field {
            flex-basis: 15%;
          }
          .coating-field {
            flex-basis: 30%;
          }
        }
      }
    }
    .line2 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .flat-width-field,
        .flat-height-field,
        .finish-width-field,
        .finish-height-field {
          flex-basis: 20%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
        .arrows-field {
          margin-top: 19px;
          flex-basis: 50px;
          text-align: center;
          font-size: 1.2rem;
        }
      }
      .column2 {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 50%;
        padding-left: 15px;
        justify-content: space-between;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .paper-stock-field {
          flex-basis: 47%;
        }
        .paper-weight-field {
          flex-basis: 47%;
        }
      }
    }
    .line3 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .self-cover-form-container {
    .line1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 30%;
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .page-count-field {
          flex-basis: 30%;
        }
        .product-type-container {
          flex-basis: 50%;
        }
      }
      .column2 {
        flex-basis: 20%;
        padding-left: 15px;
        border-right: 1px solid #333;
        padding-right: 15px;
      }
      .column3 {
        flex-basis: 50%;
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .flat-width-field,
        .flat-height-field,
        .finish-width-field,
        .finish-height-field {
          flex-basis: 20%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
        .arrows-field {
          margin-top: 19px;
          flex-basis: 50px;
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }
    .line2 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 55%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .subline1 {
          margin-bottom: 10px;
        }
        .subline1,
        .subline2 {
          display: flex;
          justify-content: space-between;
          flex-basis: 100%;
          .subtitle {
            margin-top: 22px;
            flex-basis: 5%;
            margin-left: 0;
          }
          .colors-field {
            flex-basis: 16%;
          }
          .ink-field {
            flex-basis: 22%;
          }
          .pms-field {
            flex-basis: 16%;
          }
          .coating-field {
            flex-basis: 32%;
          }
        }
      }
      .column2 {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 45%;
        padding-left: 15px;
        justify-content: space-between;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .paper-stock-field {
          width: 58%;
        }
        .paper-weight-field {
          width: 38%;
        }
      }
    }
    .line3 {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 50%;
        padding-right: 10px;
        textarea {
          height: 130px;
        }
      }
      .column2 {
        flex-basis: 40%;
        padding-left: 10px;
        padding-top: 50px;
      }
    }
  }
  .plus-cover-form-container {
    .line1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 30%;
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .page-count-field {
          flex-basis: 35%;
        }
        .product-type-container {
          flex-basis: 50%;
        }
      }
      .column2 {
        flex-basis: 20%;
        padding-left: 15px;
        border-right: 1px solid #333;
        padding-right: 15px;
      }
      .column3 {
        flex-basis: 50%;
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .flat-width-field,
        .flat-height-field,
        .finish-width-field,
        .finish-height-field {
          flex-basis: 20%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
        .arrows-field {
          margin-top: 19px;
          flex-basis: 50px;
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }
    .line2,
    .line3 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 55%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .main-title {
          flex-basis: 100%;
          color: #1976d2;
          font-size: 1.1rem;
        }
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .subline1 {
          margin-bottom: 10px;
        }
        .subline1,
        .subline2 {
          display: flex;
          justify-content: space-between;
          flex-basis: 100%;
          .subtitle {
            margin-top: 22px;
            flex-basis: 5%;
            margin-left: 0;
          }
          .colors-field {
            flex-basis: 16%;
          }
          .ink-field {
            flex-basis: 22%;
          }
          .pms-field {
            flex-basis: 16%;
          }
          .coating-field {
            flex-basis: 32%;
          }
        }
      }
      .column2 {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 45%;
        padding-left: 15px;
        justify-content: space-between;
        .title {
          margin-top: 20px;
          flex-basis: 100%;
          color: #1976d2;
        }
        .paper-stock-field {
          width: 58%;
        }
        .paper-weight-field {
          width: 38%;
        }
      }
    }
    .line4 {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 50%;
        padding-right: 10px;
        textarea {
          height: 130px;
        }
      }
      .column2 {
        flex-basis: 40%;
        padding-left: 10px;
        padding-top: 50px;
      }
    }
  }
  .envelopes-form-container {
    .line1 {
      display: flex;
      .product-type {
        flex-basis: 12%;
      }
    }
    .line2 {
      display: flex;
      border-bottom: 1px solid #333;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 35%;
        border-right: 1px solid #333;
        padding-right: 10px;
        .title {
          color: #1976d2;
        }
        .subline1 {
          margin-bottom: 8px;
        }
        .subline2 {
          border-bottom: 1px solid #333;
          padding-bottom: 10px;
        }
        .subline1,
        .subline2 {
          display: flex;
          justify-content: space-between;
          .subtitle {
            margin-top: 22px;
            flex-basis: 5%;
            margin-left: 0;
          }
          .colors-field {
            flex-basis: 18%;
          }
          .ink-field {
            flex-basis: 42%;
          }
          .pms-field {
            flex-basis: 18%;
          }
        }
        .second-title {
          flex-basis: 100%;
          padding-top: 10px;
        }
        .checkboxes {
          display: flex;
          flex-wrap: wrap;
          margin-top: 8px;
          .check {
            flex-basis: 50%;
          }
          .other-check {
            input[type="text"] {
              width: 150px;
              margin-left: 5px;
            }
          }
        }
      }
      .column2 {
        flex-basis: 30%;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #333;
        padding-top: 21px;
        .envelope-type-field {
          margin-bottom: 12px;
        }
      }
      .column3 {
        flex-basis: 35%;
        padding-left: 10px;
        .part-field {
          margin-top: 8px;
        }
        .check-field {
          margin-bottom: 8px;
        }
        .sc-job-field {
          margin-left: 25px;
          margin-bottom: 8px;
          input[type="text"] {
            width: 150px;
            margin-left: 10px;
          }
        }
      }
    }
    .line3 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .bindery-form-container {
    .line1 {
      display: flex;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .main-title {
        font-size: 1rem;
        flex-basis: 8%;
      }
    }
    .line2 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-top: 10px;
      padding-bottom: 10px;
      .field {
        flex-basis: 13%;
      }
    }
    .line3 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .data-processing-form-container {
    .line1 {
      display: flex;
      justify-content: flex-end;
      font-size: 0.95rem;
      .picker {
        cursor: pointer;
      }
    }
    .line2 {
      margin: auto;
      margin-top: 20px;
      th,
      td {
        padding: 2px 4px;
      }
      .quantity {
        width: 130px;
        text-align: right;
      }
      .price {
        width: 130px;
        text-align: right;
      }
      .unit {
        width: 130px;
        text-align: right;
      }
      .actions {
        width: 60px;
        text-align: center;
        .fa-trash {
          cursor: pointer;
          color: #d32f2f;
        }
      }
    }
    .line3 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .mail-services-form-container {
    .line1 {
      display: flex;
      justify-content: flex-end;
      font-size: 0.95rem;
      .picker {
        cursor: pointer;
      }
    }
    .line2 {
      margin: auto;
      margin-top: 20px;
      th,
      td {
        padding: 2px 4px;
      }
      .quantity {
        width: 130px;
        text-align: right;
      }
      .price {
        width: 130px;
        text-align: right;
      }
      .unit {
        width: 130px;
        text-align: right;
      }
      .actions {
        width: 60px;
        text-align: center;
        .fa-trash {
          cursor: pointer;
          color: #d32f2f;
        }
      }
    }
    .line3 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .handwork-form-container {
    .line1 {
      display: flex;
      justify-content: flex-end;
      font-size: 0.95rem;
      .picker {
        cursor: pointer;
      }
    }
    .line2 {
      margin: auto;
      margin-top: 20px;
      th,
      td {
        padding: 2px 4px;
      }
      .quantity {
        width: 130px;
        text-align: right;
      }
      .price {
        width: 130px;
        text-align: right;
      }
      .unit {
        width: 130px;
        text-align: right;
      }
      .actions {
        width: 60px;
        text-align: center;
        .fa-trash {
          cursor: pointer;
          color: #d32f2f;
        }
      }
    }
    .line3 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .large-format-form-container {
    .line1 {
      display: flex;
      .column1 {
        flex-basis: 40%;
        border-right: 1px solid #333;
        padding-right: 15px;
        .titles {
          display: flex;
          margin-bottom: 15px;
          .product-type {
            flex-basis: 120px;
          }
        }
        .sublines {
          display: flex;
          flex-wrap: wrap;
          .subcolumn1 {
            flex-basis: 100%;
            padding-right: 15px;
            padding-bottom: 15px;
            .application-line1 {
              display: flex;
              .application-column1 {
                flex-basis: 35%;
                border-right: 1px solid #333;
                padding-right: 15px;
              }
              .application-column2 {
                flex-basis: 65%;
                padding-left: 15px;
                display: flex;
                flex-wrap: wrap;
                .title {
                  color: #1976d2;
                  flex-basis: 100%;
                }
                .environment-fields {
                  flex-basis: 45%;
                }
              }
            }
            .application-line2 {
              border-top: 1px solid #333;
              margin-top: 15px;
              padding-top: 15px;
              display: flex;
              .application-column1 {
                flex-basis: 35%;
                border-right: 1px solid #333;
                padding-right: 15px;
                .title {
                  color: #1976d2;
                  flex-basis: 100%;
                }
              }
              .application-column2 {
                flex-basis: 65%;
                padding-left: 15px;
                .title {
                  color: #1976d2;
                  flex-basis: 100%;
                }
                .size-container {
                  display: flex;
                  justify-content: space-between;
                  .size-field {
                    flex-basis: 30%;
                  }
                }
              }
            }
          }
          .subcolumn2 {
            flex-basis: 100%;
            border-top: 1px solid #333;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #333;
            display: flex;
            flex-wrap: wrap;
            .title {
              color: #1976d2;
              flex-basis: 100%;
              margin-bottom: 8px;
            }
            .check-field {
              flex-basis: 165px;
            }
          }
        }
        .notes {
          margin-top: 10px;
          flex-basis: 100%;
          textarea {
            width: 100%;
            height: 110px;
          }
        }
      }
      .column2 {
        flex-basis: 60%;
        padding-left: 15px;
        margin-bottom: 10px;
        .note {
          color: #f44336;
          flex-basis: 100%;
          text-align: center;
          margin-bottom: 15px;
          justify-content: center;
        }
        .title {
          color: #1976d2;
          flex-basis: 100%;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .products {
          flex-basis: 100%;
          padding-bottom: 10px;
          border-bottom: 1px solid #333;
          display: flex;
          flex-wrap: wrap;
          .title {
            color: #1976d2;
            flex-basis: 100%;
            margin-bottom: 8px;
          }
          .check-field {
            flex-basis: 185px;
          }
        }
        .substrates {
          flex-basis: 100%;
          display: flex;
          flex-wrap: wrap;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #333;
          align-items: center;
          .check-field {
            flex-basis: 250px;
            padding-right: 15px;
            text-overflow: ellipsis;
            width: 250px;
            overflow: hidden;
            white-space: nowrap;
          }
          select {
            flex-basis: 250px;
            margin-left: 5px;
          }
        }
        .finishing {
          flex-basis: 100%;
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;
          .check-field {
            flex-basis: 250px;
            padding-right: 15px;
            text-overflow: ellipsis;
            width: 250px;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .pocket-folder-form-container {
    .main-columns {
      display: flex;
      flex-wrap: wrap;
      .main-column1 {
        flex-basis: 80%;
        border-right: 1px solid #333;
        padding-right: 15px;
      }
      .main-column2 {
        flex-basis: 20%;
        padding-left: 15px;
        padding-right: 5px;
        .field {
          margin-bottom: 8px;
        }
      }
    }
    .line1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 30%;
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .originals-field {
          flex-basis: 30%;
        }
        .product-type-container {
          flex-basis: 50%;
          .product-type {
            margin-bottom: 8px;
          }
        }
      }
      .column2 {
        flex-basis: 70%;
        padding-left: 15px;
        .title {
          color: #1976d2;
        }
        .subline1 {
          margin-bottom: 10px;
        }
        .subline1,
        .subline2 {
          display: flex;
          justify-content: space-between;
          .subtitle {
            margin-top: 22px;
            flex-basis: 5%;
            margin-left: 0;
          }
          .colors-field {
            flex-basis: 15%;
          }
          .ink-field {
            flex-basis: 20%;
          }
          .pms-field {
            flex-basis: 15%;
          }
          .coating-field {
            flex-basis: 30%;
          }
        }
      }
    }
    .line2 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 30%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .flat-width-field,
        .flat-height-field,
        .finish-width-field,
        .finish-height-field {
          flex-basis: 40%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
      }
      .column2 {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 70%;
        padding-left: 15px;
        justify-content: space-between;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .paper-stock-field {
          flex-basis: 47%;
        }
        .paper-weight-field {
          flex-basis: 47%;
        }
      }
    }
    .line3 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .ncr-form-container {
    .line1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 30%;
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .originals-field {
          flex-basis: 30%;
        }
        .product-type-container {
          flex-basis: 50%;
          .product-type {
            margin-bottom: 8px;
          }
        }
      }
      .column2 {
        flex-basis: 70%;
        padding-left: 15px;
        .title {
          color: #1976d2;
        }
        .subline1 {
          margin-bottom: 10px;
        }
        .subline1,
        .subline2 {
          display: flex;
          justify-content: space-between;
          .subtitle {
            margin-top: 22px;
            flex-basis: 5%;
            margin-left: 0;
          }
          .colors-field {
            flex-basis: 15%;
          }
          .ink-field {
            flex-basis: 20%;
          }
          .pms-field {
            flex-basis: 15%;
          }
          .coating-field {
            flex-basis: 30%;
          }
        }
      }
    }
    .line2 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 22%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .finish-width-field,
        .finish-height-field {
          flex-basis: 40%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
      }
      .column2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-basis: 28%;
        border-right: 1px solid #333;
        padding-left: 15px;
        padding-right: 15px;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .number-sets-field,
        .number-parts-field {
          flex-basis: 40%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
      }
      .column3 {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 50%;
        padding-left: 15px;
        justify-content: space-between;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .ncr-selects {
          flex-basis: 30%;
          .glue-location-field {
            margin-bottom: 6px;
          }
        }
        .backing {
          flex-basis: 30%;
        }
        .crash-number {
          flex-basis: 26%;
          .field {
            display: flex;
            justify-content: space-between;
            input {
              flex-basis: 100px;
            }
          }
        }
      }
    }
    .line3 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .notepads-form-container {
    .line1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 30%;
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .originals-field {
          flex-basis: 30%;
        }
        .product-type-container {
          flex-basis: 50%;
          .product-type {
            margin-bottom: 8px;
          }
        }
      }
      .column2 {
        flex-basis: 70%;
        padding-left: 15px;
        .title {
          color: #1976d2;
        }
        .subline1 {
          margin-bottom: 10px;
        }
        .subline1,
        .subline2 {
          display: flex;
          justify-content: space-between;
          .subtitle {
            margin-top: 22px;
            flex-basis: 5%;
            margin-left: 0;
          }
          .colors-field {
            flex-basis: 15%;
          }
          .ink-field {
            flex-basis: 20%;
          }
          .pms-field {
            flex-basis: 15%;
          }
          .coating-field {
            flex-basis: 30%;
          }
        }
      }
    }
    .line2 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-top: 10px;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 22%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .finish-width-field,
        .finish-height-field {
          flex-basis: 40%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
      }
      .column2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-basis: 28%;
        border-right: 1px solid #333;
        padding-left: 15px;
        padding-right: 15px;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .number-sets-field,
        .number-parts-field {
          flex-basis: 40%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
      }
      .column3 {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 50%;
        padding-left: 15px;
        justify-content: space-between;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .paper-stock-field {
          flex-basis: 47%;
        }
        .paper-weight-field {
          flex-basis: 47%;
        }
      }
    }
    .line3 {
      display: flex;
      justify-content: space-between;
      .column1 {
        flex-basis: 50%;
        padding-top: 10px;
        padding-right: 15px;
        textarea {
          width: 100%;
          height: 120px;
        }
      }
      .column2 {
        flex-basis: 50%;
        padding-left: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 15px;
        .title {
          flex-basis: 100%;
          color: #1976d2;
        }
        .padding-selects {
          flex-basis: 30%;
          .glue-location-field {
            margin-bottom: 6px;
          }
        }
        .backing {
          flex-basis: 30%;
        }
        .crash-number {
          flex-basis: 26%;
          .field {
            display: flex;
            justify-content: space-between;
            input {
              flex-basis: 100px;
            }
          }
        }
      }
    }
  }
  .mail-only-form-container {
    .line1 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      .column1 {
        flex-basis: 25%;
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #333;
        padding-right: 15px;
        .originals-field {
          flex-basis: 30%;
        }
        .product-type-container {
          flex-basis: 50%;
          .product-type {
            margin-bottom: 8px;
          }
        }
      }
      .column2 {
        border-right: 1px solid #333;
        flex-basis: 25%;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .title {
          color: #1976d2;
          flex-basis: 100%;
        }
        .type-materials-field {
          flex-basis: 45%;
          margin-top: 21px;
        }
        .material-coming-date-field {
          flex-basis: 45%;
        }
      }
      .column3 {
        border-right: 1px solid #333;
        flex-basis: 25%;
        padding-left: 15px;
        padding-right: 15px;
        .title {
          color: #1976d2;
          flex-basis: 100%;
        }
      }
      .column4 {
        flex-basis: 25%;
        padding-left: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .title {
          color: #1976d2;
          flex-basis: 100%;
        }
        .finish-width-field,
        .finish-height-field {
          flex-basis: 40%;
        }
        .x-field {
          flex-basis: 10px;
          margin-top: 22px;
        }
      }
    }
    .line2 {
      padding-top: 10px;
      textarea {
        width: 50%;
        height: 120px;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      margin-left: 15px;
      background-color: #bdbdbd;
      border: 1px solid #9e9e9e;
      padding: 2px 20px;
    }
    .save-button {
      width: 86px;
    }
  }
  .fa-question-circle {
    cursor: pointer;
    margin-left: 4px;
  }
  .download-estimate-button {
    width: 190px;
    margin-right: 95px;
  }
  .parts-info-container {
    display: flex;
    justify-content: space-between;
    .fields {
      .field {
        display: flex;
        .label {
          font-weight: bold;
        }
        .value {
          margin-left: 5px;
        }
      }
    }
    .parts-buttons {
      margin-top: 10px;
      button {
        margin-left: 15px;
        background-color: #bdbdbd;
        border: 1px solid #9e9e9e;
        padding: 2px 10px;
        width: 120px;
      }
    }
  }
}
