.delivery-charge-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
  }
  .fields-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .field {
      margin-bottom: 8px;
      input {
        width: 100%;
      }
    }
    .description-field {
      flex-basis: 100%;
    }
    .quantiy-field {
      flex-basis: 48%;
    }
    .price-field {
      flex-basis: 48%;
    }
  }
}

.delivery-charge-dialog {
  max-width: 500px;
  left: calc((100vw - 500px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
