.change-estimate-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
    text-align: center;
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .field {
    margin-bottom: 15px;
    input, select {
      width: 100%;
      height: 24px;
    }
  }
}

.change-estimate-dialog {
  max-width: 350px;
  left: calc((100vw - 350px) / 2);
  .modal-body, .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}