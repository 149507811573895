.reports-container {
  padding: 10px;
  border: 1px solid #333;
  margin-top: -1px;
  margin-bottom: 15px;
  .table-container {
    margin-bottom: 0;
    table {
      margin-bottom: 0;
      th {
        background-color: #9e9e9e;
        cursor: pointer;
        &.search-th {
          padding-top: 0;
          input,
          select {
            height: 20px;
            width: 100%;
          }
        }
        &.th-id {
          width: 80px;
        }
        &.th-date {
          width: 100px;
        }
      }
      td {
        padding-top: 2px;
        padding-bottom: 2px;
        .actions {
          text-align: center;
          div {
            i {
              margin-right: 15px;
              font-size: 1.2rem;
              cursor: pointer;
              color: #333;
              &:hover {
                color: #2196f3;
              }
              &.fa-trash {
                &:hover {
                  color: #e53935 !important;
                }
              }
            }
            &:last-child {
              i {
                margin-right: 0;
              }
            }
          }
          .icon-success {
            color: #43a047;
            &:hover {
              color: #43a047;
            }
          }
          .icon-error {
            color: #f44336;
            &:hover {
              color: #f44336;
            }
          }
        }
        .combined {
          font-weight: bold;
        }
      }
    }
  }
  .actions-container {
    display: flex;
    margin-top: -2px;
    align-items: center;
    justify-content: flex-end;
    .select-file-container {
      margin-left: 8px;
      display: flex;
      flex-wrap: wrap;
      .selected-file {
        flex-basis: 100%;
      }
    }
    .dropzone-container {
      border: 1px solid #bdbdbd;
      background-color: #eeeeee;
      cursor: pointer;
      // padding-left: 5px;
      padding-top: 2px;
      height: 25px;
      width: 200px;
      text-align: center;
    }
    .upload-button {
      width: 180px;
      border: 1px solid #bdbdbd;
      background-color: #eeeeee;
      margin-bottom: 8px;
      align-self: flex-start;
      height: 25px;
      margin-left: 15px;
    }
  }
}
