.wrong-estimate-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  h1 {
    font-size: 1.2rem;
    flex-basis: 100%;
  }
  .comments-field {
    margin-top: 10px;
    flex-basis: 100%;
    textarea {
      width: 100%;
      height: 120px;
    }
  }
  .files-container {
    flex-basis: 100%;
    margin-top: 15px;
    h3 {
      display: flex;
      margin-bottom: 12px;
      font-size: 0.95rem;
      align-items: center;
      font-weight: bold;
    }
    button {
      width: 100px;
      height: 25px;
      background-color: #e0e0e0;
      border: 1px solid #ccc;
      margin-bottom: 8px;
      font-size: 0.9rem;
      &:hover {
        background-color: #eeeeee;
      }
    }
    table {
      margin-bottom: 8px;
    }
    th {
      background-color: #eeeeee;
      padding: 2px 5px;
      border: 1px solid #333;
    }
    td {
      padding: 2px 5px;
      border: 1px solid #333;
    }
    .actions {
      width: 50px;
      .fa-trash {
        color: #e53935;
        cursor: pointer;
      }
      .fa-download {
        color: #000;
        cursor: pointer;
      }
    }
    .dropzone-container {
      cursor: pointer;
      margin-left: 20px;
      width: 100px;
      height: 25px;
    }
  }
}

.wrong-estimate-dialog {
  max-width: 600px;
  left: calc((100vw - 600px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
