.invoices-container {
  padding: 10px;
  border: 1px solid #333;
  margin-top: -1px;
  margin-bottom: 15px;
  .table-container {
    margin-bottom: 0;
    table {
      margin-bottom: 0;
      th {
        background-color: #9e9e9e;
        cursor: pointer;
        &.search-th {
          padding-top: 0;
          input,
          select {
            height: 20px;
            width: 100%;
          }
        }
        &.th-id {
          width: 80px;
        }
        &.th-stagecoach-number {
          width: 80px;
        }
        &.th-po-number {
          width: 120px;
        }
        &.th-qb-number {
          width: 90px;
        }
        &.th-due-date {
          width: 100px;
        }
        &.th-customer-name {
          // width: calc(67% - 620px);
        }
        &.th-sales-rep {
          width: 90px;
        }
        &.th-csr {
          width: 90px;
        }
        &.th-status {
          // width: 100px;
        }
        &.th-total {
          width: 85px;
        }
      }
      td {
        padding-top: 2px;
        padding-bottom: 2px;
        .actions {
          div {
            i {
              margin-right: 10px;
              font-size: 1rem;
              cursor: pointer;
              color: #333;
              &:hover {
                color: #2196f3;
              }
              &.fa-trash {
                &:hover {
                  color: #e53935 !important;
                }
              }
            }
            &:last-child {
              i {
                margin-right: 0;
              }
            }
            
          }
        }
        .combined {
          font-weight: bold;
        }
        &.status-box {
          text-align: center;
          font-size: 1rem;
        }
      }
    }
  }
  .actions-container {
    display: flex;
    justify-content: space-between;
    .action-buttons {
      margin-top: -6px;
      .combine-button {
        width: 160px;
        margin-right: 10px;
        background-color: #e0e0e0;
        border: 1px solid #e0e0e0;
        margin-bottom: 5px;
      }
      .export-button {
        width: 130px;
        margin-right: 10px;
        background-color: #e0e0e0;
        border: 1px solid #e0e0e0;
        margin-bottom: 5px;
      }
      .report-button {
        width: 140px;
        background-color: #e0e0e0;
        border: 1px solid #e0e0e0;
        margin-bottom: 5px;
        margin-right: 10px;
        a {
          color: #000;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .show-fields {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 4px;
      .field {
        margin-left: 30px;
        font-size: 0.8rem;
      }
    }
  }
}
