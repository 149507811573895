
@import "ApproveModal/ApproveModal.scss";
@import "PushInvoiceModal/PushInvoiceModal.scss";
@import "ReturnInvoiceModal/ReturnInvoiceModal.scss";
@import "UncombineInvoiceModal/UncombineInvoiceModal.scss";
@import "SplitInvoiceModal/SplitInvoiceModal.scss";

.pqt-invoice-container {
  background-color: #fff;
  @import 'Form/Form.scss';
}
