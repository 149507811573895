.quick-search-container {
  border: 1px solid #424242;
  background-color: #e0e0e0;
  .title {
    background-color: #9e9e9e;
    padding: 2px 5px;
    border-bottom: 1px solid #424242;
    font-weight: bold;
  }
  .search-fields {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    .search-field {
      margin-right: 15px;
      input,
      select {
        width: 150px;
        @media (max-width: 1200px) {
          width: 130px;
        }
        height: 25px;
      }
      i {
        cursor: pointer;
      }
    }
  }
}
