.steps-container {
  flex-basis: 100%;
  .step {
    background-color: #eeeeee;
    padding: 10px;
    display: flex;
    border: 1px solid #333;
    margin-bottom: 10px;
    .column1 {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 580px;
      @media (min-width: 1200px) {
        flex-basis: 580px;
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 5px;
      button {
        font-size: 0.8rem;
        height: 28px;
        border: none;
        background-color: #bdbdbd;
        margin-bottom: 10px;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 10px;
        i {
          font-size: 0.9rem;
        }
        &.unsaved {
          background-color: #43a047;
          color: #fdfdfd;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      a {
        font-size: 0.8rem;
        height: 28px;
        border: none;
        background-color: #bdbdbd;
        margin-bottom: 10px;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 10px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        i {
          font-size: 0.9rem;
          margin-right: 2px;
        }
      }
    }
    .department {
      flex-basis: 310px;
      @media (min-width: 1200px) {
        flex-basis: 310px;
      }
      select {
        margin-left: 4px;
        height: 22px;
        margin-bottom: 4px;
        width: 190px;
        @media (min-width: 1200px) {
          flex-basis: 180px;
        }
      }
      input[type="text"] {
        margin-left: 4px;
        height: 22px;
        margin-bottom: 4px;
        width: 180px;
        @media (min-width: 1200px) {
          flex-basis: 180px;
        }
      }
      .step-department {
        select {
          margin-left: 28px;
        }
      }
      .step-order {
        select {
          margin-left: 36px;
        }
      }
      .step-manager {
        select {
          margin-left: 46px;
        }
      }
      .step-po-number {
        input {
          margin-left: 30px;
        }
      }
      .step-vendor-price {
        input {
          margin-left: 22px;
        }
      }
      .step-vendor-due-date {
        input {
          margin-left: 4px;
          width: 183px;
          height: 22px;
        }
      }
      .step-promotional-due-date {
        input {
          margin-left: 28px;
          width: 190px;
          height: 22px;
        }
      }
      .step-apparel-due-date {
        input {
          margin-left: 21px;
          width: 190px;
          height: 22px;
        }
      }
      .step-shipping-method {
        select {
          margin-left: 5px;
          width: 182px;
        }
      }
      .step-tracking-number {
        input {
          margin-left: 4px;
          width: 183px;
        }
      }
      .step-postage {
        input {
          margin-left: 50px;
        }
      }
      .step-postage-field {
        select {
          margin-left: 17px;
        }
      }
      .date {
        font-size: 0.8rem;
      }
    }
    .closeout-comments {
      margin-top: 10px;
      margin-bottom: 15px;
      color: #1d4ed8;
    }
    .assign-subdepartments {
      flex-basis: 250px;
      margin-bottom: 10px;
      .assigned-subdepartments-list {
        padding-left: 12px;
        margin-top: 5px;
        .assigned-subdepartment {
          font-size: 0.8rem;
          .fa-trash {
            cursor: pointer;
            color: #b71c1c;
          }
          &.finished {
            text-decoration: line-through;
          }
        }
      }
    }
    .assigned-people {
      flex-basis: 270px;
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: 1200px) {
        flex-basis: 270px;
        padding-left: 15px;
        padding-right: 15px;
      }
      select {
        width: 130px;
        @media (min-width: 1200px) {
          width: 130px;
        }
      }
      button {
        width: 35px;
        height: 22px;
        border: none;
        background-color: #bdbdbd;
        margin-left: 5px;
        i {
          font-size: 0.7rem;
        }
      }
      .assigned-people-list {
        padding-left: 12px;
        margin-top: 5px;
        .assigned-person {
          font-size: 0.8rem;
          .fa-trash {
            cursor: pointer;
            color: #b71c1c;
          }
        }
      }
    }
    .assigned-vendors {
      flex-basis: 270px;
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: 1200px) {
        flex-basis: 270px;
        padding-left: 15px;
        padding-right: 15px;
      }
      select {
        width: 130px;
        @media (min-width: 1200px) {
          width: 180px;
        }
      }
      button {
        width: 35px;
        height: 22px;
        border: none;
        background-color: #bdbdbd;
        margin-left: 5px;
        i {
          font-size: 0.7rem;
        }
      }
      .assigned-vendors-list {
        padding-left: 12px;
        margin-top: 5px;
        .assigned-vendor {
          font-size: 0.8rem;
          .fa-trash {
            cursor: pointer;
            color: #b71c1c;
          }
        }
      }
    }
    .pause-banner {
      margin-top: 25px;
      color: #e53935;
      text-align: center;
      font-size: 24px;
      // font-weight: bold;
    }
    .data-postal-container {
      margin-top: 5px;
      margin-bottom: 5px;
      div {
        text-decoration: underline;
        color: #03a9f4;
        cursor: pointer;
      }
    }
    .notes {
      flex-basis: calc(100% - 580px);
      @media (min-width: 1200px) {
        textarea {
          height: 200px;
        }
      }
      &.no-postal {
        flex-basis: calc(100% - 580px);
        @media (min-width: 1200px) {
          flex-basis: calc(100% - 580px);
        }
        textarea {
          height: 200px;
        }
      }
      .title {
        display: flex;
        justify-content: space-between;
        .job-number {
          font-size: 1rem;
          .barcode {
            float: right;
            font-size: 2.4rem;
            font-family: "Libre Barcode 39 Extended", cursive;
            margin-top: -10px;
            margin-left: 15px;
          }
        }
      }
      textarea {
        width: 100%;
        margin-top: -18px;
        padding: 5px;
      }
      .data-postal-information {
        font-size: 0.8rem;
      }
    }
    .out-on-proof-container {
      margin-top: 5px;
      margin-bottom: 8px;
      font-size: 0.8rem;
      color: #e53935;
    }
    .dp-file-container {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 0.8rem;
    }
    .pressroom-ticket {
      margin-top: 20px;
      .fa-circle-notch {
        margin-left: 5px;
      }
    }
  }
}
