.calculator-container {
  padding: 20px;
  border: 1px solid #333;
  margin-top: -1px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  .calculator {
    background-color: #f3f4f6;
    border: 1px solid #333;
    flex-basis: 280px;
    padding: 15px;
    .field {
      // display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      input,
      select {
        width: 100%;
        height: 28px;
      }
    }
    button {
      width: 140px;
      height: 24px;
      margin-top: 8px;
      align-self: flex-end;
      margin-bottom: 2px;
      background-color: #bdbdbd;
      border: 1px solid #9e9e9e;
    }
  }
  .results {
    margin-left: 20px;
    // flex-basis: 650px;
    flex-grow: 1;
    padding: 20px;
    border: 1px solid #333;
    background-color: #f3f4f6;
    table {
      background-color: #fff;
      thead {
        tr {
          background-color: #f9fafb;
        }
      }
      tbody {
        tr {
          background-color: #f9fafb;
          &.extra-services-row {
            background-color: #fff;
          }
        }
      }
    }
  }
}
