.report-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  input {
    width: 100%;
  }
  .filters-container {
    display: flex;
    column-gap: 25px;
    .field {
      flex-basis: 28%;
    }
  }
}

.report-dialog {
  max-width: 450px;
  left: calc((100vw - 450px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}
