.delete-file-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .warning {
    margin-top: 5px;
    border: 2px solid #f44336;
    border-left: 8px solid #f44336;
    padding: 8px;
    border-radius: 3px;
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 80px;
    margin-bottom: -5px;
  }
  .error-message {
    color: #f44336;
    font-size: 0.8rem;
  }
  .comments-header {
    margin-top: 10px;
  }
}

.delete-file-dialog {
  max-width: 350px;
  left: calc((100vw - 350px) / 2);
  .modal-body, .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}