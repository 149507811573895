.header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
  .logo {
    flex-basis: 360px;
    img {
      width: 250px;
      cursor: pointer;
    }
  }
  .title {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
  }
  .menu {
    text-align: right;
    flex-basis: 580px;
    margin-top: 35px;
    .welcome {
      margin-bottom: 5px;
    }
    a {
      text-decoration: underline;
    }
    .action {
      background-color: #eeeeee;
      border: 1px solid #bdbdbd;
      margin-top: 10px;
      padding: 2px 20px;
    }
    .new-tracker-button {
      margin-left: 15px;
      width: 140px;
      height: 25px;
    }
    .new-quote-button {
      margin-left: 25px;
      width: 130px;
      height: 25px;
    }
  }
  .icons-container {
    display: flex;
    justify-content: flex-end;
    .sales-report-icon {
      margin-top: 5px;
      margin-left: 15px;
      img {
        width: 38px;
      }
    }
    .estimate-icon {
      margin-left: 15px;
      margin-top: 5px;
      img {
        width: 40px;
      }
    }
    .commissions-report-icon {
      margin-top: 5px;
      margin-left: 15px;
      img {
        width: 40px;
      }
    }
    .payroll-icon {
      margin-top: 5px;
      margin-left: 15px;
      img {
        width: 40px;
      }
    }
    .calculator-icon {
      margin-top: 5px;
      margin-left: 15px;
      img {
        width: 40px;
      }
    }
    .ap-shipping-icon {
      margin-top: 5px;
      margin-left: 15px;
      img {
        width: 40px;
      }
    }
    .call-to-action-icon {
      margin-top: 5px;
      margin-left: 15px;
      img {
        width: 40px;
      }
    }
    .task-request-icon {
      margin-top: 5px;
      margin-left: 15px;
      position: relative;
      img {
        width: 40px;
      }
      i {
        position: absolute;
        left: 12px;
        bottom: -18px;
        font-size: 1rem;
      }
    }
  }
  .no-underline {
    text-decoration: none !important;
  }
  .make-payment-icon {
    font-size: 1.3rem;
    color: #fff;
    background-color: #7e22ce;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-top: 5px;
    margin-left: 15px;
    text-decoration: none;
  }
  .cc-payment-icon  {
    font-size: 1.3rem;
    color: #fff;
    background-color: #f97316;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-top: 5px;
    margin-left: 15px;
    text-decoration: none;
  }
}
