.tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tabs {
    display: flex;
    .tab {
      padding: 2px 5px;
      background-color: #e0e0e0;
      border: 1px solid #333;
      border-left: none;
      cursor: pointer;
      &:first-child {
        border-left: 1px solid #333;
      }
      &.active {
        background-color: #fff;
        border-bottom: none;
      }
      &:hover {
        background-color: #bdbdbd;
      }
      span {
        color: #ab47bc;
        text-decoration: underline;
      }
    }
    .departments-select {
      margin-bottom: 8px;
      margin-left: 5px;
      height: 24px;
    }
  }
  .show-jobs-select {
    margin-left: 3px;
    margin-right: 15px;
  }
}
