.ap-dashboard-tabs-container {
  display: flex;
  .tab {
    background-color: #cfd8dc;
    margin-right: 2px;
    padding: 1px 10px;
    border: 1px solid #9e9e9e;
    border-bottom: none;
    cursor: pointer;
    &.active {
      background-color: #fff;
      padding-top: 3px;
      margin-top: -6px;
    }
  }
}
.create-button-container {
  position: absolute;
  right: 0;
  margin-top: -32px;
  button {
    width: 140px;
    height: 26px;
    margin-right: 10px;
    background-color: #e0e0e0;
    border: 1px solid #e0e0e0;
    margin-bottom: 5px;
    padding: 0;
  }
}

.ap-dashboard-container {
  background-color: #fff;
  @import "Table/Table.scss";
  @import "Invoices/Invoices.scss";
}

@import "Form/Form.scss";