.send-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.send-dialog {
  max-width: 350px;
  left: calc((100vw - 350px) / 2);
  .modal-body, .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}