@import "DataProcessingModal/DataProcessingModal.scss";
@import "MailModal/MailModal.scss";
@import "HandworkModal/HandworkModal.scss";
@import "ConfirmSubmitModal/ConfirmSubmitModal.scss";

.request-quote-container {
  background-color: #fff;
  @import "Form/Form.scss";
}

.popover{
  max-width: 400px; /* Max Width of the popover (depending on the container!) */
}