.pqt-tracking-container {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  @import "AuditLog/AuditLog.scss";
  @import "Steps/Steps.scss";
  @import "TrackerInformation/TrackerInformation.scss";
  @import "Form/Form.scss";
  .divider {
    flex-basis: 100%;
    border-bottom: 3px solid #bbb;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .red-box {
    color: #f44336;
  }
  .yellow-box {
    color: #f9a825;
  }
  .blue-box {
    color: #03a9f4;
  }
  .green-box {
    color: #4caf50;
  }
  .purple-box {
    color: #512da8;
  }
}

@import "PauseModal/PauseModal.scss";
@import "UnpauseModal/UnpauseModal.scss";
@import "DeleteFileModal/DeleteFileModal.scss";
