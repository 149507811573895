.pqt-layout-container {
  @import "Header/Header.scss";
  @import "Footer/Footer.scss";
  .divider {
    border-bottom: 3px solid #bbb;
    margin-left: 10px;
    margin-right: 10px;
  }
  .content {
    padding: 10px;
    margin-top: 10px;
  }
  .new-version-container {
    background-color: #8e24aa;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    color: #fff;
    font-size: 0.9rem;
    .button {
      border: 1px solid #2c0f4e;
      background-color: #4a148c;
      padding: 4px 30px;
      cursor: pointer;
      // border-radius: 4px;
      margin-left: 15px;
    }
  }
}

.red-box {
  color: #f44336;
}
.yellow-box {
  color: #ffc107;
}
.blue-box {
  color: #03a9f4;
}
.green-box {
  color: #4caf50;
}
.purple-box {
  color: #512da8;
}

@import "TermOfUseModal/TermOfUseModal.scss";