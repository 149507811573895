@import "DeleteModal/DeleteModal.scss";
@import "StopModal/StopModal.scss";

.pqt-dashboard-container {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  @import "QuickGlance/QuickGlance.scss";
  @import "QuickSearch/QuickSearch.scss";
  @import "LateJobs/LateJobs.scss";
  @import "MyJobsBoard/MyJobsBoard.scss";
  .red-box {
    color: #f44336;
  }
  .yellow-box {
    color: #f9a825;
  }
  .blue-box {
    color: #03a9f4;
  }
  .green-box {
    color: #4caf50;
  }
  .purple-box {
    color: #512da8;
  }
  .quick-container {
    //max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
  }
}
