.unpause-modal-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .notice {
    margin-top: 5px;
    border: 2px solid #ff9800;
    border-left: 8px solid #ff9800;
    padding: 8px;
    border-radius: 3px;
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  textarea {
    width: 100%;
    height: 110px;
  }
  .error-message {
    color: #f44336;
    font-size: 0.8rem;
  }
}

.unpause-dialog {
  max-width: 500px;
  left: calc((100vw - 500px) / 2);
  .modal-body, .modal-footer {
    background-color: #fff;
  }
  .action-button {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #757575;
    color: #fff;
    border: 1px solid #616161;
    border-radius: 0;
  }
  .action-button-cancel {
    background-color: #eeeeee;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 15px;
    border-radius: 0;
    border: 1px solid #e0e0e0;
  }
}