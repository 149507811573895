.chat-icon-container {
  font-size: 1.6rem;
  color: #009688;
  cursor: pointer;
  .unread-messages {
    font-size: 0.7rem;
    position: absolute;
    right: 0;
    bottom: 32px;
    animation-name: blink-chat-icon;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    color: #e91e63;
    @keyframes blink-chat-icon {
      from {
        color: #f48fb1;
      }
      to {
        color: #e91e63;
      }
    }
  }
}
