@import "DeleteModal/DeleteModal.scss";
@import "FormModal/FormModal.scss";

.help-items-container {
  background-color: #fff;
  .create-button {
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    padding: 2px 20px;
    margin-bottom: 10px;
  }
  @import 'List/List.scss';
}
