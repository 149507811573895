.submitted-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .number {
    color: #009688;
    font-weight: bold;
  }
}

.submitted-dialog {
  max-width: 400px;
  left: calc((100vw - 400px) / 2);
  .modal-body, .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}