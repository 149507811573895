@import "DeleteModal/DeleteModal.scss";

.pqt-department-view-container {
  background-color: #fff;
  @import 'QuickSearch/QuickSearch.scss';
  @import 'Jobs/Jobs.scss';
  @import 'Tabs/Tabs.scss';
  .red-box {
    color: #f44336;
  }
  .yellow-box {
    color: #f9a825;
  }
  .blue-box {
    color: #03a9f4;
  }
  .green-box {
    color: #4caf50;
  }
  .purple-box {
    color: #512da8;
  }
}
