@import "MailServicesModal/MailServicesModal.scss";
@import "ImpositionModal/ImpositionModal.scss";
@import "AdditionalServicesModal/AdditionalServicesModal.scss";
@import "MatrixModal/MatrixModal.scss";
@import "WrongEstimateModal/WrongEstimateModal.scss";
@import "ConfirmApproveModal/ConfirmApproveModal.scss";
@import "ConfirmSendModal/ConfirmSendModal.scss";
@import "ConfirmConvertModal/ConfirmConvertModal.scss";
@import "ChangeEstimateModal/ChangeEstimateModal.scss";
@import "ChangeStatusModal/ChangeStatusModal.scss";
@import "DeliveryChargeModal/DeliveryChargeModal.scss";
@import "AddPartModal/AddPartModal.scss";
@import "DeletePartModal/DeletePartModal.scss";
@import "NewPaperModal/NewPaperModal.scss";

.estimate-container {
  background-color: #fff;
  @import "Form/Form.scss";
}

.popover {
  max-width: 400px; /* Max Width of the popover (depending on the container!) */
}
