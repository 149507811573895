.finished-results-container {
  margin-top: 25px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #333;
  h2 {
    font-size: 1rem;
    font-weight: bold;
  }
  th {
    background-color: #9e9e9e;
    cursor: pointer;
  }
  td {
    padding-top: 2px;
    padding-bottom: 2px;
    .finished {
      text-decoration: line-through;
    }
    .actions {
      div {
        i {
          margin-right: 10px;
          font-size: 1rem;
          cursor: pointer;
          color: #333;
          &:hover {
            color: #2196f3;
          }
          &.fa-trash {
            &:hover {
              color: #e53935;
            }
          }
        }
        &:last-child {
          i {
            margin-right: 0;
          }
        }
      }
    }
    &.status-box {
      text-align: center;
    }
    &.chat {
      color: #9e9e9e;
      text-align: center;
      i {
        cursor: pointer;
      }
      &.unread {
        animation-name: blink-chat-list;
        animation-duration: 1.2s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        color: #e91e63;
        @keyframes blink-chat-list {
          from {
            color: #f48fb1;
          }
          to {
            color: #e91e63;
          }
        }
      }
    }
    .out-on-proof {
      color: #e53935;
    }
    .paused-text {
      font-weight: bold;
      color: #e53935;
    }
    .actions {
      text-align: center;
    }
  }
}
