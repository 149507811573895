.audit-log-container {
  flex-basis: calc(70% - 15px);
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    .job-number {
      font-size: 1rem;
      .barcode {
        float: right;
        font-size: 2.4rem;
        font-family: "Libre Barcode 39 Extended", cursive;
        margin-top: -10px;
        margin-left: 15px;
      }
    }
  }
  .log {
    border: 1px solid #333;
    margin-top: -15px;
    padding: 5px 10px;
    margin-bottom: 15px;
    height: 170px;
    overflow: auto;
    color: #666;
    .log-element {
      margin-bottom: 5px;
      font-size: 0.81rem;
      .red-value {
        color: #e53935;
      }
      .green-value {
        color: #43a047;
      }
      .purple-value {
        color: #8e24aa;
      }
      .blue-value {
        color: #1976d2;
      }
      .teal-value {
        color: #009688;
      }
      .pink-value {
        color: #e91e63;
      }
    }
  }
  .count-down {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 0.9rem;
      width: 120px;
      height: 30px;
      border: none;
      background-color: #e0e0e0;
      i {
        font-size: 1rem;
      }
      &.start-tracking-button {
        width: 140px;
        margin-left: 40px;
      }
      &.export-button {
        margin-right: 40px;
      }
      &.create-preliminary-button {
        width: 180px;
        margin-right: 40px;
      }
    }
  }
  .estimate-files {
    margin-top: 10px;
    ul {
      padding-left: 30px;
    }
  }
  .buttons-container {
    text-align: right;
    .mailing-notification {
      text-align: left;
      width: 450px;
      margin-top: 15px;
      .label {
        font-size: 0.85rem;
        margin-bottom: 5px;
      }
      input {
        width: 100%;
      }
      .save-emails-container {
        text-align: right;
        margin-top: 5px;
        button {
          width: 140px;
        }
      }
    }
    .shipping-notification {
      text-align: left;
      width: 450px;
      margin-top: 15px;
      .label {
        font-size: 0.85rem;
        margin-bottom: 5px;
      }
      input {
        width: 100%;
      }
      .save-emails-container {
        text-align: right;
        margin-top: 5px;
        button {
          width: 140px;
        }
      }
    }
  }
  .no-arworks {
    color: #ef4444;
    font-weight: bold;
    font-size: 16px;
    margin-top: 25px;
  }
  .files-container {
    width: 400px;
    margin-top: 35px;
    table {
      margin-bottom: 0;
    }
    .uploading-message {
      font-size: 0.85rem;
    }
    th {
      border: 1px solid #333;
      background-color: #e0e0e0;
      padding: 2px 5px;
    }
    td {
      border: 1px solid #333;
      padding: 2px 5px;
      i {
        cursor: pointer;
        color: #dc2626;
      }
      a {
        color: #2563eb;
      }
    }
    .browse-files-button {
      margin-left: 15px;
      background-color: #e0e0e0;
      border: 1px solid #bdbdbd;
      padding: 2px 20px;
    }
    .upload-files-button {
      background-color: #e0e0e0;
      border: 1px solid #bdbdbd;
      width: 120px;
      margin-top: 10px;
    }
    h3 {
      font-size: 0.9rem;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
