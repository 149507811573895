.dashboard-stop-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
    color: #e31e3a;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
  }
  img {
    width: 120px;
  }
  .warning {
    margin-top: 5px;
    border: 2px solid #e31e3a;
    border-left: 8px solid #e31e3a;
    padding: 8px;
    border-radius: 3px;
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.dashboard-stop-dialog {
  max-width: 450px;
  left: calc((100vw - 450px) / 2);
  .modal-body, .modal-footer {
    background-color: #fff;
  }
  .action-button {
    background-color: #ddd;
  }
  .action-button-cancel {
    background-color: #ddd;
  }
}