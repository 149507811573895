.quick-glance-container {
  flex-basis: calc(80% - 5px);
  margin-right: 10px;
  background-color: #e0e0e0;
  padding: 5px 10px;
  padding-bottom: 10px;
  border: 1px solid #424242;
  .quick-glance-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .title {
      font-weight: bold;
    }
    .legend {
      text-align: right;
    }
    .reset {
      background-color: #eeeeee;
      border: 1px solid #bdbdbd;
      padding: 2px 10px;
      font-size: 0.8rem;
      margin-left: 10px;
      &.disabled {
        color: #999;
        cursor: default;
      }
    }
  }
  .elements {
    display: flex;
    .quick-element {
      margin-right: 15px;
      border: 1px solid #424242;
      background-color: #fff;
      .title {
        background-color: #9e9e9e;
        padding: 2px 5px;
        border-bottom: 1px solid #424242;
        display: flex;
        .name {
          width: calc(100% - 72px);
        }
      }
      .list {
        padding: 2px 5px;
        .list-element {
          display: flex;
          justify-content: space-between;
          .name {
            cursor: pointer;
            width: calc(100% - 80px);
          }
          .not-started {
            width: 24px;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
          }
          .number {
            text-decoration: underline;
            cursor: pointer;
            text-align: center;
            width: 24px;
          }
          .total {
            text-align: center;
            width: 24px;
            text-decoration: underline;
            cursor: pointer;
          }
          .closeout-total {
            width: 50px;
            text-align: right;
            margin-right: 5px;
          }
        }
        .active {
          background-color: #e0e0e0;
        }
      }
    }
    .csr-count {
      flex-basis: 19%;
      .blue-dot {
        color: #1976d2;
        width: 20px;
        margin-right: 6px;
      }
      .green-dot {
        color: #388e3c;
        width: 20px;
        margin-right: 6px;
      }
      .black-dot {
        color: #333;
        width: 20px;
      }
      .out-on-proof {
        margin-top: 15px;
        font-size: 0.95rem;
        color: #f44336;
      }
      .paused {
        margin-top: 15px;
        font-size: 0.95rem;
        color: #f44336;
      }
    }
    .sales-rep {
      flex-basis: 19%;
      .blue-dot {
        color: #1976d2;
        width: 20px;
        margin-right: 6px;
      }
      .green-dot {
        color: #388e3c;
        width: 20px;
        margin-right: 6px;
      }
      .black-dot {
        color: #333;
        width: 20px;
      }
    }
    .job-snap-shot {
      flex-basis: 19.5%;
      .blue-dot {
        color: #1976d2;
        width: 20px;
        margin-right: 6px;
      }
      .green-dot {
        color: #388e3c;
        width: 20px;
        margin-right: 6px;
      }
      .black-dot {
        color: #333;
        width: 20px;
      }
    }
    .departmental-job-count {
      flex-basis: 22.5%;
      margin-right: 15px;
      border: 1px solid #424242;
      background-color: #fff;
      .yellow-dot {
        color: #fbc02d;
        width: 20px;
        margin-right: 6px;
      }
      .green-dot {
        color: #388e3c;
        width: 20px;
        margin-right: 6px;
      }
      .black-dot {
        color: #333;
        width: 20px;
      }
      .title {
        background-color: #9e9e9e;
        padding: 2px 5px;
        border-bottom: 1px solid #424242;
        display: flex;
        .name {
          width: calc(100% - 72px);
        }
      }
      .lists {
        display: flex;
        .list {
          flex-basis: 100%;
          padding: 2px 5px;
          display: flex;
          flex-wrap: wrap;
          .list-element {
            flex-basis: 100%;
            display: flex;
            justify-content: space-between;
            .name {
              cursor: pointer;
              width: calc(100% - 80px);
            }
            .not-started {
              width: 24px;
              text-align: center;
              text-decoration: underline;
              cursor: pointer;
            }
            .number {
              text-decoration: underline;
              cursor: pointer;
              text-align: center;
              width: 24px;
            }
            .total {
              text-align: center;
              width: 24px;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
    .double-list {
      flex-basis: 20.5%;
      .shift-count {
        flex-basis: 100%;
      }
      .my-closeout {
        flex-basis: 100%;
      }
      .my-invoices {
        flex-basis: 100%;
        margin-top: 10px;
        a {
          width: 100%;
          display: flex;
          justify-content: space-between;
          text-decoration: none;
          color: #000;
        }
      }
      .escrow {
        margin-top: 10px;
        .list {
          padding-left: 5px;
        }
        .name,
        .number {
          cursor: default !important;
        }
        .name {
          width: calc(100% - 120px) !important;
        }
        .number {
          text-decoration: none !important;
          width: 100px !important;
          font-weight: bold;
          color: #000;
        }
      }
      .quick-element {
        margin-right: 0;
      }
    }
  }
  .invoice-row-divider {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .pp-invoices {
    margin-top: 20px;
    .pp-title {
      font-weight: bold;
    }
    .pp-row {
      display: flex;
      justify-content: space-between;
      padding-right: 8px;
    }
  }
}
