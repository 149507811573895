.jobs-container {
  padding: 10px;
  border: 1px solid #333;
  margin-top: -1px;
  margin-bottom: 15px;
  .table-container {
    margin-bottom: 0;
    table {
      margin-bottom: 0;
      tr {
        &.not-ready {
          td {
            color: #9e9e9e;
            &.chat {
              color: #9e9e9e;
              text-align: center;
              i {
                cursor: pointer;
              }
              &.unread {
                animation-name: none;
                color: #9e9e9e;
              }
            }
            .out-on-proof {
              color: #9e9e9e;
            }
          }
        }
      }
      th {
        background-color: #9e9e9e;
        cursor: pointer;
      }
      td {
        padding-top: 2px;
        padding-bottom: 2px;
        .actions {
          div {
            i {
              margin-right: 10px;
              font-size: 1rem;
              cursor: pointer;
              color: #333;
              &:hover {
                color: #2196f3;
              }
              &.fa-trash {
                &:hover {
                  color: #e53935 !important;
                }
              }
            }
            &:last-child {
              i {
                margin-right: 0;
              }
            }
          }
        }
        &.status-box {
          text-align: center;
        }
        &.chat {
          color: #9e9e9e;
          text-align: center;
          i {
            cursor: pointer;
          }
          &.unread {
            animation-name: blink-chat-list;
            animation-duration: 1.2s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
            color: #e91e63;
            @keyframes blink-chat-list {
              from {
                color: #f48fb1;
              }
              to {
                color: #e91e63;
              }
            }
          }
        }
        .out-on-proof {
          color: #e53935;
        }
        .finished {
          text-decoration: line-through;
        }
      }
    }
  }
  .paused-text {
    font-weight: bold;
    color: #e53935;
  }
}
