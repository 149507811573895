.chat-members-container {
  border: 1px solid #009688;
  border-left: none;
  height: 80vh;
  width: 200px;
  .title {
    background-color: #eceff1;
    padding: 8px 0;
    font-weight: bold;
    border-bottom: 1px solid #009688;
    margin-bottom: 10px;
    font-size: 0.9rem;
    margin-left: 10px;
    margin-right: 10px;
    i {
      margin-right: 5px;
      cursor: pointer;
      font-size: 1rem;
    }
  }
  .members {
    overflow: auto;
    max-height: calc(100% - 100px);
    padding-left: 10px;
    padding-right: 10px;
    .member-element {
      margin-bottom: 5px;
      i {
        color: #00796b;
      }
    }
  }
  .close-button {
    width: 45px;
    text-align: center;
    cursor: pointer;
    margin: auto;
    margin-top: 10px;
    color: #009688;
    text-decoration: underline;
    font-size: 0.9rem;
  }
}
