.tracker-information-container {
  flex-basis: 30%;
  border: 1px solid #333;
  margin-right: 15px;
  padding: 10px;
  .title {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: center;
  }
  .edit-button {
    display: flex;
    justify-content: space-evenly;
    button {
      font-size: 0.8rem;
      width: 110px;
      height: 30px;
      border: none;
      background-color: #e0e0e0;
      i {
        font-size: 0.9rem;
      }
    }
  }
  .postage-red {
    color: #e53935;
  }
}
