.pqt-department-container {
  background-color: #fff;
  @import 'CloseOut/CloseOut.scss';
  @import 'Jobs/Jobs.scss';
  @import 'Tabs/Tabs.scss';
  .red-box {
    color: #f44336;
  }
  .yellow-box {
    color: #ffc107;
  }
  .blue-box {
    color: #03a9f4;
  }
  .green-box {
    color: #4caf50;
  }
  .purple-box {
    color: #512da8;
  }
}
